<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Create Expense</h4>
          <form>
            <!-- 1ST ROW -->
            <div class="row">
              <div class="col-4 mb-2 mx-auto">
                <h6>Vendor</h6>
                <vue-simple-suggest
                  :min-length="0"
                  v-model="form.vendorName"
                  :list="vendorList"
                  display-attribute="vendor_name"
                  value-attribute="vendor_id"
                  :filter-by-query="true"
                  name="Vendor Name"
                  autocomplete="off"
                ></vue-simple-suggest>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                    >{{ errors.first("Vendor Name") }}</span
                  >
                </p>
              </div>
              <template>
                <div class="col-4 mb-2">
                  <h6>Invoice Number</h6>
                  <!-- v-validate="{ required: true, regex: /^\S+$/ }" -->
                  <vs-input
                    type="text"
                    name="Invoice Number"
                    v-model="form.invoice_number"
                    class="w-full"
                  />
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Invoice Number')"
                      >{{ errors.first("Invoice Number") }}</span
                    >
                  </p>
                </div>

                <div class="col-4 mb-2">
                  <h6>Invoice Date</h6>
                  <datepicker
                    placeholder="From Date"
                    @selected="onSelectDate($event)"
                    format="dd-MM-yyyy"
                    v-model="form.invoice_date"
                  ></datepicker>
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Invoice Date')"
                      >{{ errors.first("Invoice Date") }}</span
                    >
                  </p>
                </div>
                <!-- :disabled="toDateDisable" -->
                <div class="col-4 mb-2">
                  <h6>Payment Due Date (Optional)</h6>
                  <datepicker
                    placeholder="To Date"
                    :disabledDates="endDisabledDates"
                    v-model="form.payment_due_date"
                    format="dd-MM-yyyy"
                  ></datepicker>
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Payment Due Date')"
                      >{{ errors.first("Payment Due Date") }}</span
                    >
                  </p>
                </div>
              </template>
              <div class="col-4 mb-2">
                <h6>Project</h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Category</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                    >{{ errors.first("Category Name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 2ND ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Subcategory</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                    >{{ errors.first("Subcategory Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Country</h6>
                <multiselect
                  label="country_name"
                  class="w-full"
                  track-by="value"
                  v-model="form.countryValue"
                  name="Priority"
                  :options="countryList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.country_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>On Behalf Of</h6>
                <multiselect
                  label="user_name"
                  class="w-full"
                  track-by="user_id"
                  v-model="form.onBehalfId"
                  name="Approver"
                  :options="approverList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.user_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Approver')"
                    >{{ errors.first("Approver") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 3rd ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Amount</h6>
                <vs-input
                  type="number"
                  name="Amount"
                  @input="totalAmount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                    >{{ errors.first("Amount") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Mode of payment</h6>
                <multiselect
                  label="payment_mode_name"
                  class="w-full"
                  track-by="payment_mode_id"
                  v-model="form.modeOfPayment"
                  name="Mode of payment"
                  :options="paymentMode"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.payment_mode_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Mode of payment')"
                    >{{ errors.first("Mode of payment") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Priority</h6>
                <multiselect
                  label="priority_name"
                  class="w-full"
                  track-by="priority_id"
                  v-model="form.priorityValue"
                  name="Priority"
                  :options="priority"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.priority_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>
            </div>
            <!-- v-if="isCreditNote == 1" -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Tax (Optional)</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      :disabled="form.amount == 0"
                      label="gst_name"
                      class="w-full"
                      track-by="gst_id"
                      v-model="form.gst_id"
                      name="gst"
                      @select="gstCalculate"
                      :options="gstList"
                      :searchable="false"
                      :allow-empty="false"
                      selectLabel="select"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.gst_name
                      }}</template>
                    </multiselect>
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Priority')"
                        >{{ errors.first("Priority") }}</span
                      >
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      :disabled="gst_name != 'Manual Amount'"
                      type="number"
                      name="GST"
                      v-model="form.gst_amount"
                      @input="totalAmount"
                      class="w-full"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 mb-2">
                <h6>Total Amount</h6>
                <vs-input
                  type="number"
                  name="GST"
                  v-model="form.total_amount"
                  class="w-full"
                  placeholder=""
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>
              <div
                class="col-4 mb-2"
                v-if="
                  isShootLocationActive == true ||
                  isShootLocationActive == 'true'
                "
              >
                <h6>Set Name (Optional)</h6>
                <multiselect
                  label="location_name"
                  class="w-full"
                  track-by="location_id"
                  v-model="form.shootLocation"
                  name="location_name"
                  :options="shootLocationList"
                  :searchable="true"
                  :allow-empty="true"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('location_name')"
                    >{{ errors.first("location_name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 4th ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Comment</h6>
                <vs-textarea
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                    >{{ errors.first("Comment") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2 doc-upload">
                <h6>Upload Document</h6>
                <vue-dropzone
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-error-multiple="vdropzoneError"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-added-files="vdropzoneFilesAdded"
                  @vdropzone-success-multiple="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                ></vue-dropzone>
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="feather icon-trash mt-2"
                ></vs-button>
              </div>
              <div class="col-4 mb-2">
                <h6>Already Approved</h6>
                <vs-switch v-model="isAlreadyPaid" />
              </div>
            </div>
          </form>

          <!-- BUTTONS :disabled="submitStatus" -->
          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="
                this.uploadedDocument == '-' || dupinvoice != ''
                  ? true
                  : submitStatus
              "
              type="filled"
              @click.prevent="submitForm"
              class="mr-3"
              >Submit</vs-button
            >
            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import projectService from "@/services/projectService.js";
import CashManagementService from "@/services/CashManagementService.js";
import CountryService from "@/services/countryService.js";
import UserService from "@/services/UserService.js";
import ExpenseService from "@/services/expenseService.js";
import MethodService from "@/services/methodService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSuggest,
    VueSimpleSuggest,
  },

  beforeMount() {
    this.getProjectsList();
    this.getCountryList();
    this.getVendorList();
    this.getMasterList();
    this.getShootLocationList();

    this.organization_id = localStorage.getItem("OrganizationID");

    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      submitStatus: false,
      isAlreadyPaid: false,
      gstInputDisabled: true,
      dupinvoice: "",
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      toDateDisable: true,
      gstList: [
        {
          gst_name: "CGST",
          gst_percentage: 18,
          gst_id: 1,
        },
        {
          gst_name: "SGST",
          gst_percentage: 9,
          gst_id: 2,
        },
      ],
      isGstSelected: true,
      form: {
        invoice_number: "",
        payment_due_date: "",
        invoice_date: "",
        gst_id: "",
        gst_amount: 0,
        vendorName: "",
        categoryValue: "",
        subcategoryValue: "",
        amount: 0,
        comment: "",
        priorityValue: "",
        modeOfPayment: "",
        countryValue: "",
        onBehalfId: "",
        shootLocation: "",
        total_amount: 0,
      },
      priority: [],
      paymentMode: [],
      shootLocationList: [],
      countryList: [],
      approverList: [],
      vendorList: [],
      projectList: [],
      categoryList: [],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      uploadedDocumentArray: [],
      gst_name: "",
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (!this.form.invoice_number) {
          delete this.form.invoice_number;
        }
        if (!this.form.invoice_date) {
          delete this.form.invoice_date;
        }
        if (!this.form.payment_due_date) {
          delete this.form.payment_due_date;
        }
        if (!this.form.gst_id) {
          delete this.form.gst_id;
        }
        if (!this.form.gst_amount) {
          delete this.form.gst_amount;
        }
        if (!this.form.shootLocation) {
          delete this.form.shootLocation;
        }
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    isShootLocationActive() {
      return window.localStorage.getItem("isShootLocation");
    },
  },
  methods: {
    // gstCalculateAmount() {
    //   if (this.form.gst_id) {
    //     this.gstCalculate(this.form.gst_id);
    //   }
    // },

    gstCalculate(selected) {
      this.gst_name = selected.gst_name;
      this.gst_percentage = selected.gst_percentage;
      this.form.gst_amount = 0;

      let amount =
        this.form.amount > 0 ? parseFloat(this.form.amount).toFixed(2) : 0;

      let totalAmount =
        (parseFloat(selected.gst_percentage).toFixed(2) * amount) / 100;
      this.form.gst_amount = parseFloat(totalAmount).toFixed(2);
      this.totalAmount();
    },
    totalAmount() {
      let amount =
        this.form.amount != "" ? parseFloat(this.form.amount).toFixed(2) : 0;

      this.form.total_amount =
        (this.form.gst_amount ? parseFloat(this.form.gst_amount) : 0) +
        parseFloat(amount);
    },
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.toDateDisable = false;
    },
    getShootLocationList: function () {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == true) {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.priority = data.data.priority;
            this.paymentMode = data.data.payment_mode;
            this.gstList = data.data.gst;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.vendorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getMapList: function (id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.approverList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCountryList: function () {
      CountryService.getCountry()
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.countryList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projectList.push(x);
                }
              });
            } else {
              this.projectList = data.data;
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },
    onProjectSelect: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getAllUplodeFiles: function () {
      this.uploadFilePopup = true;
    },
    onChangeCheckUploadFile: function (doc) {
      window.open(doc);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },

    submitForm: function () {
      this.$vs.loading();

      setTimeout((x) => {
        let payload = {
          project_id: this.form.projectName
            ? this.form.projectName.project_id
            : 0,
          po_name: this.form.vendorName ? this.form.vendorName : "",
          item_name: "-",
          company_name: "-",
          customFieldValues: [],
          category_id: this.form.subcategoryValue
            ? this.form.subcategoryValue.category_id
            : 0,
          company_address: "-",
          price: this.form.amount,
          currency: "INR",
          sgst: "0",
          cgst: "0",
          total: this.form.amount,
          purchase_image: this.uploadedDocument,
          country_id: 100,
          purchase_description: this.form.comment,
          purchase_status: "0",
          approved_percentage: "0",
          purchase_active: "1",
          location_id: this.form.shootLocation
            ? this.form.shootLocation.location_id
            : "",
          payment_mode: this.form.modeOfPayment
            ? this.form.modeOfPayment.payment_mode_id
            : "",
          payment_mode_description: "-",
          priority: this.form.priorityValue
            ? this.form.priorityValue.priority_id
            : "",
          purchase_type: "expense",
          approver_id: "0",
          city_id: "-",
          onBehalfId: this.form.onBehalfId ? this.form.onBehalfId.user_id : 0,
          onBehalfName: "",
          isAlreadyPaid: this.isAlreadyPaid ? 1 : 0,
          vendorName: this.form.vendorName,
          vendor_id: 0,
          invoice_number: this.form.invoice_number
            ? this.form.invoice_number
            : "",
          payment_due_date: this.form.payment_due_date
            ? this.form.payment_due_date
            : "",
          invoice_date: this.form.invoice_date ? this.form.invoice_date : "",
          gst_id: this.form.gst_id ? this.form.gst_id.gst_id : 0,
          gst_amount: this.form.gst_amount ? this.form.gst_amount : 0,
          total_amount: this.form.total_amount,
        };
        let vendorCheck = this.vendorList.filter((x) => {
          return x.vendor_name == this.form.vendorName;
        });
        if (vendorCheck.length > 0) {
          payload["vendor_id"] = vendorCheck[0].vendor_id;
        }

        ExpenseService.addExpense(payload)
          .then((response) => {
            const { data } = response;

            this.$vs.loading.close();
            if (data.Status == true) {
              this.$vs.notify({
                title: "Created!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
            }
            this.clearForm();
          })
          .catch((error) => {
            this.$vs.notify({
              title: "Error!",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      }, 1000);
    },

    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function (file) {},

    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },
    clearFile: function () {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
    },
    vdropzoneComplete: function (response) {
      for (let i = 0; i < response.length; i++) {
        this.selectedFilesUUID.push(response[i].upload.uuid);
      }
    },
    vdropzoneError: function (file, message, xhr) {},
    vremoved: function (file) {
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
    },

    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      this.form = {
        vendorName: "",
        categoryValue: "",
        invoice_number: "",
        amount: "",
        comment: "",
        priorityValue: "",
        onBehalfId: "",
        modeOfPayment: "",
        countryValue: "",
      };
      this.submitStatus = true;
      this.reason = "";
      this.uploadedDocument = "-";
      this.$validator.reset();
      this.clearFile();
    },
  },
};
</script>
