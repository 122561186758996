var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-tabs',[(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.CreateExpenses
            ) || _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"Create Expense","icon-pack":"feather","icon":"icon-box"}},[_c('div',{staticClass:"tab-text"},[_c('div',[_c('create-expense-view')],1)])]):_vm._e(),(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.ViewProjectExpense
            ) 
              ||
              _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"View Expenses","icon-pack":"feather","icon":"icon-box"}},[_c('div',[_c('expenses')],1)]):_vm._e(),(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.ExpenseStatue
            ) || _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"Expense Status","icon-pack":"feather","icon":"icon-box"}},[_c('div',[_c('expense-status-view')],1)]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }