<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full mb-base">
        <vx-card class="form-title" title="Find Expense">
          <div class="row">
            <div class="col-6 mb-3">
              <h6>Project</h6>
              <multiselect
                v-model="projects_option"
                track-by="project_id"
                label="project_name"
                @input="({ project_id }) => (this.form.project_id = project_id)"
                :options="projects"
                name="Project Name"
                @select="getCategory"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.project_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-3">
              <h6>User</h6>

              <multiselect
                v-model="users_option"
                track-by="user_id"
                label="user_name"
                @input="({ user_id }) => (this.form.user_id = user_id)"
                :options="users"
                name="User Name"
                placeholder="Select User"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.user_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('User Name')"
                  >{{ errors.first("User Name") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-6 mb-3">
              <h6>Category</h6>
              <!-- <multiselect
                v-model="category_value"
                track-by="category_id"
                :custom-label="nameWithParent"
                label="category_name"
                @input="({category_id})=> this.category_id = category_id"
                :options="categories"
                name="Category Name"
                :searchable="true"
                :allow-empty="true"
                :clear-on-select="false"
                :preselect-first="true"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.category_name }}
                  <span
                    class="text-primary"
                    v-show="option.parent_category_name != null"
                  >{{" [" + option.parent_category_name +"]" }}</span>
                </template>
              </multiselect>-->
              <multiselect
                v-model="categoryValue"
                track-by="category_id"
                label="category_name"
                @input="
                  ({ category_id }) => (this.form.category_id = category_id)
                "
                :options="categories"
                name="Category Name"
                placeholder="Select Category"
                @select="onSuggestSelect"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.category_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Category Name')"
                  >{{ errors.first("Category Name") }}</span
                >
              </p>
            </div>

            <div class="col-6 mb-3">
              <h6>Subcategory</h6>
              <multiselect
                v-model="subcategoryValue"
                track-by="category_id"
                @input="
                  ({ category_id }) => (this.form.category_id = category_id)
                "
                label="category_name"
                :options="subcategories"
                name="subcategory Name"
                placeholder="Select Category"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.category_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('subcategory Name')"
                  >{{ errors.first("subcategory Name") }}</span
                >
              </p>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-6 mb-3">
              <h6>Status</h6>
              <multiselect
                v-model="statuses_option"
                track-by="status_id"
                label="status_name"
                @input="({ status_id }) => (this.form.status_id = status_id)"
                :options="statuses"
                name="Status Name"
                placeholder="Select Status"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.status_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Status Name')"
                  >{{ errors.first("Status Name") }}</span
                >
              </p>
            </div>
            <div class="col-6">
              <h6>Date</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 mr-2">
                  <!-- <flat-pickr
                    @on-change="onToChange"
                    :config=" configFromdateTimePicker"
                    v-model="form.dateFrom"
                    placeholder="From Date"
                  />-->
                  <datepicker
                    placeholder="From Date"
                    :disabledDates="startDisabledDates"
                    @selected="onSelectDate($event)"
                    format="dd-MM-yyyy"
                    v-model="form.dateFrom"
                  ></datepicker>
                  <!-- <datepicker
                    :disabledDates="disabledDates"
                    @selected="onSelectDate($event)"
                    :minimumView="'day'"
                    v-model="selectedDate"
                    :maximumView="'day'"
                    placeholder="From Date"
                  ></datepicker>-->
                </div>
                <div class="w-100 ml-2">
                  <!-- <flat-pickr
                    :config="configTodateTimePicker"
                    v-model="form.dateTo"
                    @on-change="onFromChange"
                  placeholder="To Date"-->
                  <datepicker
                    placeholder="To Date"
                    :disabledDates="endDisabledDates"
                    v-model="form.dateTo"
                    format="dd-MM-yyyy"
                    :disabled="toDateDisable"
                  ></datepicker>
                  <!-- @selected="onSelectToDate()" -->
                  <!-- <datepicker
                    :disabledDates="disabledDates"
                    @selected="onSelectDate($event)"
                    :minimumView="'day'"
                    v-model="selectedDate"
                    :maximumView="'day'"
                    placeholder="From Date"
                  ></datepicker>-->
                </div>
              </div>

              <!-- @on-change="onToChange" -->
              <!-- <datetime-picker-start-end /> -->
            </div>
          </div>

          <!-- ****** -->
          <div class="row">
            <div class="col-6 mb-3">
              <!-- {{ vendors.vendor_pan }} -->
              <h6>Pan Card number</h6>
              <multiselect
                v-model="form.vendorPan"
                track-by="vendor_id"
                label="vendor_pan"
                :options="pancardList"
                name="Vendor Pan"
                placeholder="Select Vendor Pan"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.vendor_pan }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Vendor Pan')"
                  >{{ errors.first("Vendor Pan") }}</span
                >
              </p>
            </div>

            <div class="col-6 mb-3">
              <h6>VHC Code</h6>
              <multiselect
                v-model="form.vhcCode"
                track-by="VHC_code"
                label="VHC_code"
                :options="VHCList"
                name="VHC Code"
                placeholder="VHC Code"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.VHC_code }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('VHC Code')"
                  >{{ errors.first("VHC Code") }}</span
                >
              </p>
            </div>
          </div>
          <!-- ****** -->

          <!-- Darshan | 29th Oct, 2020 -->

          <div class="row" v-if="isTally == 1">
            <div class="col-6 mb-3">
              <h6>Vendor</h6>
              <multiselect
                v-model="vendor_options"
                track-by="vendor_id"
                label="vendor_name"
                @input="({ vendor_id }) => (this.form.vendor_id = vendor_id)"
                :options="vendors"
                name="Vendor Name"
                placeholder="Select Vendor"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.vendor_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Vendor Name')"
                  >{{ errors.first("Vendor Name") }}</span
                >
              </p>
            </div>

            <div class="col-6 mb-3">
              <h6>Invoice Number</h6>
              <vs-input
                type="text"
                name="Invoice Number"
                v-model="form.invoice_number"
                class="w-full"
                placeholder="Enter Invoice Number"
              />

              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Invoice Number')"
                  >{{ errors.first("Invoice Number") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Set Name</h6>
              <multiselect
                label="location_name"
                class="w-full"
                track-by="location_id"
                v-model="form.shootLocation"
                name="location_name"
                :options="shootLocationList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <!-- <template slot="singleLabel" slot-scope="{ option }">{{
                    option.currency_code
                  }}</template> -->
              </multiselect>
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('location_name')"
                  >{{ errors.first("location_name") }}</span
                >
              </p>
            </div>
            <div class="col-3 mb-2">
              <h6>Payment Due Date</h6>
              <!-- :disabledDates="endDisabledDates" -->
              <datepicker
                placeholder="To Date"
                v-model="form.payment_due_date"
                format="dd-MM-yyyy"
              ></datepicker>
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Payment Due Date')"
                  >{{ errors.first("Payment Due Date") }}</span
                >
              </p>
            </div>
            <div class="col-3 mb-2">
              <h6>Tally Expenses</h6>
              <multiselect
                label="syncType"
                class="w-full"
                track-by="syncId"
                v-model="form.tallyType"
                name="syncType"
                :options="tallyTypeList"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <!-- <template slot="singleLabel" slot-scope="{ option }">{{
                    option.currency_code
                  }}</template> -->
              </multiselect>
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('location_name')"
                  >{{ errors.first("location_name") }}</span
                >
              </p>
            </div>
          </div>

          <!-- Darshan | 29th Oct, 2020 -->

          <!-- ********************************************************************************************************* -->
          <div class="row">
            <div class="col-6 mx-auto text-center">
              <vs-button
                class="mr-3 mb-2"
                :disabled="submitStatus"
                @click.prevent="submitForm()"
                >Submit</vs-button
              >
              <vs-button
                color="warning"
                type="border"
                class="mb-2"
                @click.prevent="clearForm()"
                >Reset</vs-button
              >
            </div>
          </div>
          <!-- ********************************************************************************************************* -->
        </vx-card>
      </div>
    </div>

    <div class="vx-col w-full">
      <vs-tabs v-model="tabIndexCheck">
        <vs-tab label="Expenses" icon-pack="feather" icon="icon-box">
          <!-- EXPENSS LIST -->
          <div>
            <expense-list
              :expenseList.sync="expense"
              :totalPageCheck="totalPages"
              :tabIndex="tabIndexCheck"
              :allExp="allExp"
              :total="total"
            ></expense-list>
          </div>
        </vs-tab>

        <vs-tab label="Purchase Orders" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <!-- PURCHASE ORDER LIST -->
            <div>
              <po-list
                :expenseList.sync="po_list"
                :tabIndex="tabIndexCheck"
                :totalPageCheck="totalPages"
              ></po-list>
            </div>
          </div>
        </vs-tab>

        <vs-tab
          label="Advance Cash Requests"
          icon-pack="feather"
          icon="icon-box"
        >
          <div class="tab-text">
            <!-- PURCHASE ORDER LIST -->
            <div>
              <cr-list
                :expenseList.sync="cr_list"
                :tabIndex="tabIndexCheck"
                :totalPageCheck="totalPages"
                :allExp="allExp"
              ></cr-list>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import UserService from "@/services/UserService.js";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import expenseService from "@/services/expenseService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import Datepicker from "vuejs-datepicker";
import { BASEURL } from "@/config/index.js";
import flatPickr from "vue-flatpickr-component";

import poList from "@/views/apps/expenses/poList.vue";
import crList from "@/views/apps/expenses/crList.vue";
import expenseList from "@/views/apps/expenses/expenseList.vue";
import moment from "moment";

import "flatpickr/dist/flatpickr.css";
const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    AgGridVue,
    flatPickr,
    VxTour,
    poList,
    Datepicker,
    expenseList,
    DatetimePickerStartEnd,
    crList,
  },
  data() {
    return {
      allExp: [],
      total: 0,
      loginUserRole: window.localStorage.getItem("UserRole"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: new Date(),
        to: null,
      },
      category_value: [],
      isActiveFirst: true,
      submitStatus: true,
      CurrentPage: 1,
      totalPages: 0,
      toDate: "",
      fromDate: "",
      configTodateTimePicker: {
        maxDate: new Date(),
        dateFormat: "d-m-Y",
        minDate: null,
      },
      configFromdateTimePicker: {
        maxDate: new Date(),
        dateFormat: "d-m-Y",
        minDate: null,
      },
      excelurl: BASEURL(),
      pageNo: null,
      po_list: [],
      cr_list: [],
      tabIndexCheck: 0,
      User: "Select User",
      toDateDisable: true,
      projects: [],
      users: [],
      vendors: [],
      pancardList: [],
      VHCList: [],
      // categories: [],
      statuses: [
        { status_name: "Approved", status_id: 1 },
        { status_name: "Rejected", status_id: 2 },
        { status_name: "Pending", status_id: "0" },
      ],
      projects_option: [],
      users_option: [],
      vendor_options: [],
      // category_option: [],
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null,
      statuses_option: [],
      expense: [],
      form: {
        project_id: "",
        user_id: "",
        vendor_id: "",
        invoice_number: "",
        category_id: "",
        status_id: "",
        dateFrom: "",
        dateTo: "",
        vendorPan: "",
        vhcCode: "",
        shootLocation: "",
        payment_due_date: "",
        tallyType: "",
      },
      shootLocationList: [],
      tallyTypeList: [
        {
          syncId: "0",
          syncType: "Synced Expenses",
        },
        {
          syncId: "1",
          syncType: "Unsynced Expenses",
        },
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      // fromDate: null,
      // toDate: null,
      // configFromdateTimePicker: {
      //   minDate: null,
      // },
      // configTodateTimePicker: {
      //   maxDate: new Date(),
      // },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Project",
          field: "project_name",
          width: 275,
          filter: true,
          // checkboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // headerCheckboxSelection: true,
        },
        {
          headerName: "User",
          field: "user_name",
          filter: true,
          width: 275,
        },
        // {
        //   headerName: "Pending Level",
        //   field: "lastname",
        //   filter: true,
        //   width: 275,
        // },
        {
          headerName: "Category",
          field: "category_name",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Sub Category",
          field: "sub_category_name",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Amount (₹)",
          field: "total",
          filter: true,
          width: 200,
        },
        // {
        //   headerName: "Expense Date",
        //   field: "city",
        //   filter: true,
        //   width: 200,
        // },
        {
          headerName: "Status",
          field: "purchase_status",
          filter: false,
          width: 150,
          cellRenderer: function (params) {
            if (params.value == 0) {
              return `<div class="text-warning font-bold">Pending<div>`;
            } else if (params.value == 1) {
              return `<div class="text-success font-bold">Approved<div>`;
            } else if (params.value == 2) {
              return `<div class="text-danger font-bold">Rejected<div>`;
            }
          },
        },
        // {
        //   headerName: "Edit",
        //   field: "",
        //   filter: false,
        //   width: 80,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
        //   },
        // },
        {
          headerName: "Download",
          field: "",
          filter: false,
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function (params) {
            return '<span class="text-center table-edit-icon"><i class="text-center material-icons">save_alt</i></span>';
          },
        },
        // {
        //   headerName:
        //     this.archive == "archive" ? "Unarchive" : "Archive",
        //   field: "",
        //   filter: false,
        //   width: 150,
        //   cellStyle: { "text-align": "center" },
        //   cellRenderer: function(params) {
        //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
        //   },
        // },
      ],
      contacts: [],
      isTally: "",
      permissionPageAccess: "",
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] ||
              newVal[x] !== undefined ||
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
    tabIndexCheck: {
      handler: function (newVal, oldVal) {
        if (this.form.project_id != "") {
          this.CurrentPage = 1;
          this.submitForm();
        }
      },
      deep: true,
    },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // this.getExpenseList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    getShootLocationList: function () {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == false) {
          } else {
            this.shootLocationList = data.data;
            //  console.log(this.shootLocationList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.form.dateTo = "";
      this.toDateDisable = false;
    },
    // onSelectToDate: function() {
    //   if (!this.form.dateFrom) {
    //     this.$vs.notify({
    //       title: "Select From Date",
    //       iconPack: "feather",
    //       icon: "check_box",
    //       color: "warning"
    //     });
    //   }
    // },
    nameWithParent({ category_name, parent_category_name }) {
      if (parent_category_name == null) {
        return `${category_name}`;
      } else {
        return `${category_name} — [${parent_category_name}]`;
      }
    },
    updateSearchQuery(val) {
      // this.gridApi.setQuickFilter(val);
      // this.getFilterExpList();
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "maxDate", dateStr);
    },
    getUsersList: function () {
      let filter = {
        limit: this.paginationPageSize,
      };
      UserService.UserList(filter)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.users = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      this.projects = [];
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("3P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
            //this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getVendorsList: function () {
      this.vendors = [];
      this.pancardList = [];
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendors = data.data;

            data.data.map((x) => {
              if (x.vendor_pan != null) {
                this.pancardList.push(x);
              }
            });

            this.VHCList = data.vhc_data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCategory: function (id) {
      this.categoryValue = null;
      this.subcategoryValue = null;
      let projectID = id.project_id;
      this.getCategoryList(projectID);
    },
    // getCategoryList: function(id) {
    //   let ID = id.project_id;
    //   CategoryService.getAllcategoriesByProject(ID)
    //     .then(response => {
    //       const { data } = response;
    //       console.log("data", data);
    //       if (data.Status == false) {
    //       } else {
    //         this.categories = data.data;
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    getCategoryList: function (projectId) {
      CategoryService.getProjectMainCategories(projectId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
            // this.categories.map(x => {
            //   if (this.params.data.parent_id === x.category_id) {
            //     this.categoryValue = x;
            //     this.form.parent_id = x.category_id;
            //     this.getSubcategoryList(x.category_id);
            //   }
            // });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onSuggestSelect: function (selected) {
      (this.subcategoryValue = null),
        this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function (ID) {
      // let pCateId = this.categoryValue.category_id;
      let pId = this.projects_option.project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            // this.subcategories.map(x => {
            //   if (this.params.data.category_id === x.category_id) {
            //     this.subcategoryValue = x;
            //     this.form.category_id = x.category_id;
            //   }
            // });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.projects_option = [];
      this.users_option = [];
      this.vendor_options = [];
      // this.category_option = [];
      (this.categoryValue = null),
        (this.subcategoryValue = null),
        (this.statuses_option = []);
      this.$validator.reset();
      this.submitStatus = true;
      this.expense = [];
      // this.getExpenseList();
    },
    getExpenseList: function (currentPage) {
      let payload = {
        page: currentPage,
        type: 0,
      };
      expenseService
        .getExpense(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status == false || data.Status == "false") {
          } else {
            // this.expense = data.data;
            this.expense = data.data.filter((x) => {
              return String(x.approver_id) == "0";
            });
            this.po_list = data.data.filter((x) => {
              return x.approver_id != 0 && x.request_type == "NORMAL";
            });
            this.cr_list = data.data.filter((x) => {
              return x.approver_id != 0 && x.request_type == "CASH";
            });
            this.totalPages = data.pagination.last_page;
          }
        })
        .catch((error) => {});
    },
    submitForm(e) {
      this.expense = [];
      this.$validator.validateAll().then((result) => {
        if (this.form.dateFrom) {
          if (!this.form.dateTo) {
            this.$vs.notify({
              title: "Error!",
              text: "select To date",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return false;
          }
          // this.$vs.loading.close();
        }

        if (this.categoryValue) {
          if (!this.subcategoryValue) {
            this.$vs.notify({
              title: "",
              text: "Select Subcategory to filter",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            return false;
          }
        }

        this.$vs.loading();
        if (result) {
          //  if(!this.form.dateFrom){
          //    this.form.dateFrom = ""
          //  }
          //  if(!this.form.dateTo){
          //    this.form.dateTo = ""
          //  }

          let fromDate = moment(this.form.dateFrom).format("YYYY-MM-DD");
          let toDate = moment(this.form.dateTo);
          this.fromDate = moment(this.form.dateFrom).format("YYYY-MM-DD");
          this.toDate = moment(this.form.dateTo)
            .add(1, "d")
            .format("YYYY-MM-DD");

          if (fromDate == "Invalid date") {
            fromDate = "";
            this.fromDate = "";
          }
          if (toDate == "Invalid date") {
            toDate = "";
            this.toDate = "";
          }
          this.getFilterExpList();

          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getFilterExpList: function () {
      let payload = {
        projectId: this.form.project_id,
        userId: this.form.user_id,
        vendorId: this.form.vendor_id,
        invoiceNo: this.form.invoice_number,
        // page_no: e || 1,
        categoryId: this.form.category_id,
        purchaseStatus: this.form.status_id,
        locationId: this.form.shootLocation
          ? this.form.shootLocation.location_id
          : "",
        tallyType: this.form.tallyType ? this.form.tallyType.syncId : "",
        payableDueDate: this.form.payment_due_date
          ? moment(this.form.payment_due_date).format("YYYY-MM-DD")
          : "",
        dateFrom: this.fromDate,
        dateTo: this.toDate,
        search: this.searchQuery,
        type: this.tabIndexCheck, // 'DD-MM-YYYY'
        page_no: this.CurrentPage,
        VHC_code: this.form.vhcCode.hasOwnProperty("VHC_code")
          ? this.form.vhcCode.VHC_code
          : "",
        vendor_pan: this.form.vendorPan.hasOwnProperty("vendor_pan")
          ? this.form.vendorPan.vendor_pan
          : "",
      };

      expenseService
        .getExpenseByFilter(payload)
        .then((response) => {
          this.allExp = [];
          this.expense = [];
          this.po_list = [];
          this.cr_list = [];

          this.allExpense(payload);

          this.$vs.loading.close();
          const { data } = response;
          if (this.form.dateFrom) {
            if (data.data.length <= 0) {
              this.$vs.notify({
                title: "No Expense/PO recorded for this date range",
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          }
          if (data.Status == true || data.Status == "true") {
            if (this.tabIndexCheck == 1) {
              this.po_list = data.data;
            } else if (this.tabIndexCheck == 2) {
              this.cr_list = data.data;
            } else {
              this.expense = data.data;
            }
            this.total = data.total_amount ? data.total_amount : 99;
            this.totalPages = data.pagination.last_page;
            this.$forceUpdate();
            // setTimeout(() => {
            //   eventBus.$emit("refreshexpTable", this.expense);
            // }, 1);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          this.$vs.loading.close();
          // this.submitStatus = true;
        });
    },
    allExpense: function (payload) {
      delete payload.page_no;
      console.log("payload", payload);

      expenseService.getExpenseByFilter(payload).then((response) => {
        const { data } = response;
        if (data.Status == true || data.Status == "true") {
          this.allExp = data.data;
        }
      });
    },
  },
  mounted() {
    this.getUsersList();
    this.getProjectsList();
    this.getVendorsList();
    eventBus.$on("getPageNumber", (e) => {
      let count = 1;
      if (this.form.project_id == "") {
        count = 0;
      }
      this.CurrentPage = e;
      if (count == 1) {
        this.submitForm(e);
      }
    });
    //
  },
  beforeMount() {
    this.getShootLocationList();
    this.isTally = localStorage.getItem("isTally");
    this.permissionPageAccess = localStorage.getItem("permissionPageAccess")
      ? localStorage.getItem("permissionPageAccess").split(",")
      : "";
  },
};
</script>
