var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"text-center table-edit-icon",on:{"click":function($event){return _vm.getPreview()},"mouseover":function($event){return _vm.getExpenseSettings()}}},[_c('i',{staticClass:"text-center material-icons"},[_vm._v("remove_red_eye")])]),_c('div',{staticClass:"demo-alignment"},[_c('vs-popup',{attrs:{"id":'popmodalView' + _vm.params.data.purchase_id,"title":"Expense Details","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[(
          _vm.expenseSettings.showInvoiceNumber && _vm.expenseSettings.showGstPopup
        )?_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Expense Name:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.po_name))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Invoice Number:")]),_c('div',{},[_c('h5',{staticClass:"text-break"},[_vm._v(_vm._s(_vm.params.data.invoice_number))])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Currency:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.currency))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Payment Mode:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.payment_mode_name))])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Invoice Date:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.invoice_date))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("GST Name:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.gst_name))])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Tax Amount:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.gst_amount))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Total Amount")]),_c('div',{},[_c('h5',[_vm._v("\n                  "+_vm._s(_vm.params.data.total_amount
                      ? _vm.params.data.total_amount.toLocaleString("en-IN")
                      : "")+"\n                ")])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Set Name:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.location_name))])])])])])]):_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Expense Name:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.po_name))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Payment Mode:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.payment_mode_name))])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Currency:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.currency))])])])]),_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Total Amount")]),_c('div',{},[_c('h5',[_vm._v("\n                  "+_vm._s(_vm.params.data.total_amount
                      ? _vm.params.data.total_amount.toLocaleString("en-IN")
                      : "")+"\n                ")])])])])]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('div',[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Set Name:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.location_name))])])])])])]),(
          _vm.params.data.credit_invoice_no != '' &&
          _vm.params.data.credit_invoice_no != null
        )?_c('div',{staticClass:"vx-col w-full border-bottom mb-6"},[_c('h3',{staticClass:"text-center mb-4"},[_vm._v("-Credit Note Details-")]),_c('div',{staticClass:"mx-0 row mb-6"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Credit Amount :")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.credit_amount))])])]),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-muted small mb-1"},[_vm._v("Credit Invoice No:")]),_c('div',{},[_c('h5',[_vm._v(_vm._s(_vm.params.data.credit_invoice_no))])])])])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }