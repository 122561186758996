<template>
  <div>
    <div class="row mt-5" v-if="expenseList.length > 0">
      <div
        class="col-md-6 mb-4"
        v-for="(item, index) in expenseList"
        :key="index"
      >
        <div class="row border mx-0 rounded overflow-hidden">
          <!-- @click="popupActivo=true" -->
          <div class="col-4 pl-0">
            <img
              :src="item.purchase_image.split(',')[0] + '?token=' + tempToken"
              style="height: 25vh; width: 100%; object-fit: cover; object-position: center center;"
              alt
            />
          </div>
          <div class="col-8 py-3">
            <p class="mb-2">
              <span class="font-weight-bold mr-1">Name:</span>
              {{ item.po_name }}
            </p>

            <p class="mb-2">
              <span class="font-weight-bold mr-1">Details:</span>
              {{ item.purchase_description }}
            </p>

            <p class="mb-2">
              <span class="font-weight-bold mr-1">Project:</span>
              {{ item.project_name }}
            </p>

            <p class="mb-2">
              <span class="font-weight-bold mr-1">Created By:</span>
              {{ item.user_name }}
            </p>

            <p class="mb-2">
              <span class="font-weight-bold mr-1">Created On:</span>
              {{ item.created_date }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="noData">
      <h5 class="text-center my-3">No Expense Found</h5>
    </div>
    <vs-popup
      class="holamundo"
      title="Lorem ipsum dolor sit amet"
      :active.sync="popupActivo"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    </vs-popup>
  </div>
</template>

<script>
import ExpenseService from "@/services/expenseService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
export default {
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      tempToken: window.localStorage.getItem("TempToken"),
      permissionPageAccess: window.localStorage.getItem("permissionPageAccess"),
      loginUserRole: window.localStorage.getItem("UserRole"),
      noData: false,
      popupActivo: false,
      expenseList: []
    };
  },
  mounted() {
    this.getExpense();
  },
  methods: {
    getExpense() {
      let matches = [];
      if (
        this.permissionPageAccess &&
        this.loginUserRole != "Admin" &&
        !this.permissionPageAccessArry.includes(
          PermissionVariables.ViewOrganizationProjects
        )
      ) {
        let str = this.permissionPageAccess.split(",");
        for (let p = 0; p < str.length; p++) {
          if (str[p].charAt(0) == "5" && str[p].charAt(1) == "P") {
            matches.push(str[p]);
          }
        }
      }

      let filter = {
        purchaseStatus: 0,
        permissionProject: matches
          .toString()
          .split("5P")
          .join("")
      };
      this.$vs.loading();

      // console.log("RolesService :", RolesService);
      ExpenseService.getAllExpense(filter)
        .then(response => {
          const { data } = response;
          if (data.Status && data.data.length > 0) {
            this.expenseList = data.data;
          } else {
            this.noData = true;
          }
          this.$vs.loading.close();
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log("error :", error);
        });
    }
  }
};
</script>

<style></style>
