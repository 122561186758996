import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getAllMethods(filters, sort) {
    return Api().get(`/v2/method?organization_id=${organization_id()}`);
  },
  getAllMaster() {
      return Api().get(`/v2/master?organization_id=${organization_id()}`);
  }
};
