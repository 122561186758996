<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- <vx-card class="form-title" title="Create Daily Cost Report"> -->
        <div class="form-title">
          <h4 class>Create Daily Cost Report</h4>
          <form>
            <!-- 1ST ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Project</h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projects"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Date</h6>
                <datepicker
                  placeholder="Date"
                  format="dd-MM-yyyy"
                  v-model="form.dcr_date"
                ></datepicker>
              </div>
              <div class="col-4 mb-2">
                <h6>Category</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoriesByProject"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                    >{{ errors.first("Category Name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 2ND ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Subcategory</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                    >{{ errors.first("Subcategory Name") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Previous DCR Particular's</h6>
                <div>
                  <multiselect
                    v-model="particular_date"
                    :options="particularDates"
                    :searchable="false"
                    @select="showAllParticulars"
                    :close-on-select="true"
                    open-direction="bottom"
                    :show-labels="false"
                    placeholder="Particulars Date"
                  ></multiselect>
                </div>
              </div>

              <div class="col-4 mb-2">
                <h6>Description</h6>
                <vs-textarea
                  name="Description"
                  v-model="description"
                  class="w-full mb-0"
                  rows="3"
                />
              </div>
            </div>

            <div v-for="(item, index) in vendorArray" :key="index">
              <div
                class="vx-row m-3"
                style="border: 1px solid #ecc7c7; padding: 10px"
              >
                <div class="row col-11">
                  <div class="col-4 mb-2">
                    <h6>Vendor Name</h6>
                    <vs-input
                      type="text"
                      name="Particular Name"
                      v-model="item.dcr_particular_name"
                      class="w-full"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>
                      <label class="mb-0 c_pointer" :for="'One' + index">
                        <input
                          @change="checkCostType(index)"
                          type="radio"
                          class="mb-0 mr-2"
                          :id="'One' + index"
                          value="2"
                          v-model="item.shiftType"
                        />Per Day</label
                      >
                    </h6>
                    <vs-input
                      type="number"
                      name="Per Day"
                      v-model="item.per_day"
                      class="w-full"
                      @input="totalCalculatedCost(item.per_day, index)"
                      :disabled="item.shiftType == '1'"
                    />
                  </div>

                  <div class="col-2 mb-2">
                    <h6>
                      <label class="mb-0 c_pointer" :for="'Two' + index">
                        <input
                          @change="checkCostType(index)"
                          type="radio"
                          :id="'Two' + index"
                          class="mb-0 mr-2"
                          value="1"
                          v-model="item.shiftType"
                        />Shift
                      </label>
                    </h6>
                    <vs-input
                      type="number"
                      name="shift"
                      v-model="item.shift"
                      class="w-full"
                      @input="totalCalculatedCost(item.shift, index)"
                      :disabled="item.shiftType == '2'"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Unit Cost</h6>
                    <vs-input
                      type="number"
                      name="Unit Cost"
                      v-model="item.unit_cost"
                      class="w-full"
                      @input="totalCalculatedCost(item.unit_cost, index)"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Calculated Cost</h6>
                    <vs-input
                      type="number"
                      name="calculatedCost"
                      v-model="item.calculatedCost"
                      class="w-full"
                      disabled
                    />
                  </div>
                </div>
                <div class="row col-11">
                  <div class="col-4 mb-2">
                    <h6>Extra Cost</h6>
                    <vs-input
                      type="number"
                      name="Extra Cost"
                      v-model="item.extra_cost"
                      class="w-full"
                      @input="total_costTotal(item.extra_cost, index)"
                    />
                  </div>
                  <div class="col-6 mb-2">
                    <h6>Reason</h6>
                    <vs-input
                      type="text"
                      :disabled="item.extra_cost <= 0"
                      name="Reason"
                      v-model="item.extra_cost_reason"
                      class="w-full"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Final Cost</h6>
                    <vs-input
                      type="number"
                      name="Final Cost"
                      v-model="item.total_cost"
                      class="w-full calculated_cost"
                      disabled
                    />
                  </div>
                </div>
                <div style="margin-top: -20px; margin-left: 50px">
                  <feather-icon
                    v-if="vendorArray.length == index + 1"
                    color="blue"
                    @click="addMoreVendors(index)"
                    icon="PlusCircleIcon"
                    svgClasses="h-12 w-12"
                  />
                  <feather-icon
                    v-else
                    @click="removeVendors(item, index)"
                    icon="MinusCircleIcon"
                    svgClasses="h-12 w-12"
                  />
                </div>
              </div>
            </div>
          </form>

          <!-- BUTTONS -->
          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mr-3"
              >Submit</vs-button
            >
            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
          </div>
        </div>
        <!-- </vx-card> -->
      </div>
    </div>

    <!-- ALL PARTICULARS  -->

    <vs-popup
      fullscreen
      class="holamundo particularsPopup"
      :title="'Previous Particulars : (' + particular_date + ')'"
      :active.sync="popupActivo"
    >
      <div class="pp-section">
        <div v-for="(item, index) in previousParticulars" :key="index">
          <div class="m-3" style="border: 1px solid #ecc7c7; padding: 10px">
            <div class="row w-100 align-items-center">
              <div class="col-11">
                <div class="row">
                  <div class="col-4 mb-2">
                    <h6>Vendor Name</h6>
                    <vs-input
                      type="text"
                      name="Particular Name"
                      v-model="item.dcr_particular_name"
                      class="w-full"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Per Day</h6>
                    <vs-input
                      type="number"
                      name="Per Day"
                      v-model="item.per_day"
                      class="w-full"
                      @input="totalCalculatedCost(item.per_day, index)"
                      :disabled="item.shiftType == '1'"
                    />
                  </div>

                  <div class="col-2 mb-2">
                    <h6>Shift</h6>
                    <vs-input
                      type="number"
                      name="shift"
                      v-model="item.shift"
                      class="w-full"
                      @input="totalCalculatedCost(item.shift, index)"
                      :disabled="item.shiftType == '2'"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Unit Cost</h6>
                    <vs-input
                      type="number"
                      name="Unit Cost"
                      v-model="item.unit_cost"
                      class="w-full"
                      @input="totalCalculatedCost(item.unit_cost, index)"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Calculated Cost</h6>
                    <vs-input
                      type="number"
                      name="calculatedCost"
                      v-model="item.calculatedCost"
                      class="w-full"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 mb-2">
                    <h6>Extra Cost</h6>
                    <vs-input
                      type="number"
                      name="Extra Cost"
                      v-model="item.extra_cost"
                      class="w-full"
                      @input="total_costTotal(item.extra_cost, index)"
                    />
                  </div>
                  <div class="col-6 mb-2">
                    <h6>Reason</h6>
                    <vs-input
                      type="text"
                      name="Reason"
                      v-model="item.extra_cost_reason"
                      class="w-full"
                    />
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Final Cost</h6>
                    <vs-input
                      type="number"
                      name="Final Cost"
                      v-model="item.total_cost"
                      class="w-full calculated_cost"
                    />
                  </div>
                </div>
              </div>

              <div class="col-1 text-center border-left">
                <vs-checkbox
                  v-model="ppArray"
                  :vs-value="item.dcr_particular_id"
                  @change.native="check(item, index, $event)"
                ></vs-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-3 fixed-btn">
          <vs-button @click="addPreviousParticular()">Submit</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import Datepicker from "vuejs-datepicker";
import MethodService from "@/services/methodService.js";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";

import projectService from "@/services/projectService.js";
import CashManagementService from "@/services/CashManagementService.js";
import CurrencyService from "@/services/currencyService.js";
import UserService from "@/services/UserService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

import dcrService from "@/services/dcrService.js";
import moment from "moment";
export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSuggest,
    VueSimpleSuggest,
  },
  beforeMount() {
    let vendorObj = {
      dcr_particular_name: "",
      per_day: "",
      shift: "",
      unit_cost: "",
      shiftType: "2",
      calculatedCost: "",
      extra_cost: "0",
      extra_cost_reason: "",
      total_cost: "",
    };
    this.vendorArray.push(vendorObj);

    this.getProjectsList();
    this.getCurrencyList();
    this.getVendorList();
    this.getMasterList();
    this.organization_id = localStorage.getItem("OrganizationID");
    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      projectName: "",
      radios1: "per_day",
      popupActivo: false,
      ppArray: [],
      description: "",
      currencyList: [],
      ApproverList: [],
      BehalfOfList: [],
      projects: [],
      paymentMode: [],
      categoriesByProject: [],
      checkBox: "",
      organization_id: "",
      submitStatus: true,
      DCR_total_cost: 0,
      previousParticulars: [],
      form: {
        projectName: "",
        // credit_date: "",
        categoryValue: "",
        subcategoryValue: "",
      },
      particular_date: "",
      particularDates: [],
      previousParticularsArray: [],
      particularsUniqueArray: [],
      singleParticularValue: [],
      vendorArray: [],
      vendorList: [],
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      projectList: [
        {
          project_name: "abc",
          project_id: 1,
        },
      ],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length - 1;
        console.log("handler form", newVal, oldVal, len);

        if (
          len <=
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    isDaySelected() {
      if (this.radios1 == "per_day") {
        return true;
      } else {
        return false;
      }
    },
    isShiftSelected() {
      if (this.radios1 == "Shift") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // this.form.projectName = window.localStorage.getItem("projectName");
    // this.projectName = window.localStorage.getItem("projectName");
    // this.project_id = parseInt(window.localStorage.getItem("projectID"));
    // this.getAllCategoryListByProject(this.project_id);
    // this.getMapList(this.project_id);
    // this.getAllParticularsDates(this.project_id);
  },
  beforeDestroy() {},
  methods: {
    addMoreVendors(clickIndex) {
      if (this.vendorArray[clickIndex].dcr_particular_name) {
        let vendorObj = {
          dcr_particular_name: "",
          per_day: "",
          shift: "",
          unit_cost: "",
          shiftType: "2",
          calculatedCost: "",
          extra_cost: "0",
          extra_cost_reason: "",
          total_cost: "",
        };
        this.vendorArray.push(vendorObj);
      } else {
        this.$vs.notify({
          // title: "Somthing Went Wrong",
          text: "Please Fill the form",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },

    checkCostType(index) {
      this.vendorArray[index].shift = "";
      this.vendorArray[index].per_day = "";
      this.vendorArray[index].unit_cost = "";
      this.vendorArray[index].calculatedCost = "";
      this.vendorArray[index].extra_cost = "0";
      this.vendorArray[index].total_cost = "";
    },

    totalCalculatedCost(value, index) {
      if (this.vendorArray[index].shiftType == "2") {
        this.vendorArray[index].calculatedCost =
          parseInt(this.vendorArray[index].per_day) *
          parseInt(this.vendorArray[index].unit_cost);
        this.total_costTotal(null, index);
      } else {
        this.vendorArray[index].calculatedCost =
          parseInt(this.vendorArray[index].shift) *
          parseInt(this.vendorArray[index].unit_cost);
        this.total_costTotal(null, index);
      }
    },

    total_costTotal(value, index) {
      if (this.vendorArray[index].shiftType == "2") {
        this.vendorArray[index].total_cost =
          parseInt(this.vendorArray[index].per_day) *
            parseInt(this.vendorArray[index].unit_cost) +
          parseInt(this.vendorArray[index].extra_cost);
      } else {
        this.vendorArray[index].total_cost =
          parseInt(this.vendorArray[index].shift) *
            parseInt(this.vendorArray[index].unit_cost) +
          parseInt(this.vendorArray[index].extra_cost);
      }
    },

    check(item, index, e) {
      if (e.srcElement.checked) {
        let newItem = item;
        if (newItem.shift > 0) {
          Object.assign(newItem, {
            shiftType: "1",
          });
        } else {
          Object.assign(newItem, {
            shiftType: "2",
          });
        }
        this.previousParticularsArray.push(newItem);
      } else {
        this.previousParticularsArray.map((x, index) => {
          if (x.dcr_particular_id == item.dcr_particular_id) {
            this.previousParticularsArray.splice(index, 1);
          }
        });
      }
      this.singleParticularValue = item;
    },

    addPreviousParticular() {
      this.vendorArray = [];
      let vendorObj = {
        dcr_particular_name: "",
        per_day: "",
        shift: "",
        unit_cost: "",
        shiftType: "2",
        calculatedCost: "",
        extra_cost: "0",
        extra_cost_reason: "",
        total_cost: "",
      };
      this.vendorArray.push(vendorObj);
      this.popupActivo = false;

      const ind = this.ppArray.indexOf(
        this.singleParticularValue.dcr_particular_id
      );

      let ppID = this.singleParticularValue;

      if (ind > -1) {
        this.particularsUniqueArray = [
          ...new Set(this.previousParticularsArray.map((x) => x)),
        ];
      } else {
        this.particularsUniqueArray = this.particularsUniqueArray.filter(
          function (value, index, arr) {
            return value.dcr_particular_id !== ppID.dcr_particular_id;
          }
        );
      }
      this.vendorArray = [...this.particularsUniqueArray, ...this.vendorArray];

      this.vendorArray.map((x, index) => {
        if (x.hasOwnProperty("shiftType")) {
          this.totalCalculatedCost(x, index);
        }
      });
    },

    showAllParticulars(item) {
      this.popupActivo = true;
      this.getAllPreviousParticulars(item);
    },

    removeVendors(val, index) {
      console.log(`val`, val);

      const ind = this.ppArray.indexOf(val.dcr_particular_id);
      if (ind > -1) {
        this.ppArray.splice(ind, 1);
        // this.vendorArray.splice(ind, 1);
      }

      let PPid = val.dcr_particular_id;
      this.vendorArray = this.vendorArray.filter(function (value, index, arr) {
        return value.dcr_particular_id !== PPid;
      });
      this.particularsUniqueArray = this.particularsUniqueArray.filter(
        function (value, index, arr) {
          return value.dcr_particular_id !== PPid;
        }
      );
      this.previousParticularsArray = this.particularsUniqueArray.filter(
        function (value, index, arr) {
          return value.dcr_particular_id !== PPid;
        }
      );
    },

    getAllPreviousParticulars(DCRval) {
      this.$vs.loading();

      let projectId = this.project_id;
      let dcrDate = DCRval;

      dcrService
        .getPreviousParticulars(projectId, dcrDate)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.previousParticulars = data.data;
          } else {
            this.$vs.notify({
              title: "Somthing went wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            //this.priority = data.data.priority;
            this.paymentMode = data.data.payment_mode;
            //this.gstList = data.data.gst;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(selected.category_id, this.project_id);
    },
    onSelectBehalf(selected) {
      this.getApproverList(this.project_id, selected.user_id);
    },
    onProjectSelect: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
      this.getAllParticularsDates(this.project_id);
    },

    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data;
            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCurrencyList: function () {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.currencyList = data.data;
            console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllUplodeFiles: function () {
      console.log("this.is :>> ", this.isDocuments);
      this.uploadFilePopup = true;
    },
    onChangeCheckUploadFile: function (doc) {
      window.open(doc);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },
    categoryList: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
      this.getAllParticularsDates(this.project_id);
    },

    getAllParticularsDates(ID) {
      dcrService
        .getParticularsDates(ID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.particularDates = data.dcrDates;
          } else {
            this.$vs.notify({
              title: "Somthing went wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          // this.$vs.loading.close();
        })
        .catch((error) => {
          // this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getMapList: function (id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.BehalfOfList = data.data;
            // console.log(this.ApproverList);
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getApproverList: function (id, user_id) {
      let payload = {
        id: id,
        user_id: user_id,
      };
      UserService.getApproverList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.ApproverList = data.data;
            // console.log(this.ApproverList);
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    submitForm: function () {
      this.$vs.loading();

      const UserID = window.localStorage.getItem("UserID");

      this.vendorArray.map((x, index) => {
        console.log(x.total_cost, "x.total_cost");
        if (!isNaN(x.total_cost)) {
          this.DCR_total_cost += parseInt(x.total_cost);
        }
      });
      let props = ["budget_id", "dcr_id", "dcr_particular_id"];

      const allVendorArray = this.vendorArray.map((x) => {
        var hasAll = props.every((prop) => x.hasOwnProperty(prop));
        console.log(`hasAll`, hasAll);
        if (hasAll) {
          x.budget_id = "0";
          x.dcr_id = "0";
          x.dcr_particular_id = "0";
        } else {
          if (x.per_day == "") {
            x.per_day = "0";
          } else if (x.shift == "") {
            x.shift = "0";
          }
        }
        return x;
      });
      console.log("this.DCR_total_cost", this.DCR_total_cost);
      setTimeout((x) => {
        let payload = {
          dcr_id: 0,
          budget_id: 1,
          project_id: this.project_id,
          user_id: UserID,
          dcr_isActive: 1,
          dcr_particulars: allVendorArray,
          dcr_description: this.description,
          dcr_final_cost: this.DCR_total_cost,
          project_id: this.project_id,
          dcr_date: moment(this.form.dcr_date).format("YYYY-MM-DD"),
          category_id: this.form.subcategoryValue.category_id,
        };

        dcrService
          .addDCR(payload)
          .then((response) => {
            const { data } = response;
            if (data.Status == true) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.clearForm();
            } else {
              this.$vs.notify({
                title: "Something went wrong",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            // this.$vs.notify({
            //   title: "Error!",
            //   text: error.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning",
            // });
          });
      }, 500);
    },

    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },
    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocument = response.data.image_path;
      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    clearFile: function () {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      this.vendorArray = [];
      this.DCR_total_cost = 0;
      this.particular_date = "";
      this.form = {
        projectName: "",
        // credit_date: "",
        categoryValue: "",
        subcategoryValue: "",
      };
      let vendorObj = {
        dcr_particular_name: "",
        per_day: "",
        shift: "",
        unit_cost: "",
        shiftType: "2",
        calculatedCost: "",
        extra_cost: "0",
        extra_cost_reason: "",
        total_cost: "",
      };
      this.vendorArray.push(vendorObj);
      this.description = "";
      this.submitStatus = true;
      this.uploadedDocument = "-";
      this.clearFile();
    },
  },
};
</script>

<style></style>
