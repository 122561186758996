<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Delivery Challan</h4>
          <form>
            <!-- 1ST ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Challan Name</h6>
                <vs-input
                  type="text"
                  name="Challan"
                  v-model="form.challanName"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Challan')"
                    >{{ errors.first("Challan") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Project</h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Select PO</h6>
                <multiselect
                  label="po_name"
                  class="w-full"
                  track-by="value"
                  v-model="form.purchaseOrder"
                  name="Purchase Order"
                  :options="purchaseOrderList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.po_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Purchase Order')"
                    >{{ errors.first("Purchase Order") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 2nd ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Currency</h6>
                <multiselect
                  label="currency_code"
                  class="w-full"
                  track-by="currency_id"
                  v-model="form.currency"
                  name="Currency"
                  :options="currencyList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.currency_code
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Currency')"
                    >{{ errors.first("Currency") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Amount</h6>
                <vs-input
                  type="text"
                  name="Amount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                    >{{ errors.first("Amount") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 3nd ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Comment</h6>
                <vs-textarea
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                    >{{ errors.first("Comment") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2 doc-upload">
                <h6>Upload Document</h6>
                <vue-dropzone
                  @vdropzone-error="vdropzoneError"
                  @vdropzone-complete="vdropzoneComplete"
                  @vdropzone-files-added="vdropzoneFilesAdded"
                  @vdropzone-success="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                ></vue-dropzone>
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="mr-3 feather icon-trash"
                />
              </div>
            </div>
          </form>

          <!-- BUTTONS -->
          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="
                submitStatus == false && fileUpload.deleteButtonStatus == true
                  ? false
                  : true
              "
              type="filled"
              @click.prevent="submitForm"
              class="mr-3"
              >Submit</vs-button
            >

            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";

import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";

import projectService from "@/services/projectService.js";
import PurchaseOrderService from "@/services/purchaseOrderService.js";
import CurrencyService from "@/services/currencyService.js";
import ChallanService from "@/services/challanService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSuggest,
    VueSimpleSuggest,
  },
  beforeMount() {
    this.getProjectsList();
    this.getCurrencyList();
    this.getVendorList();
    this.organization_id = localStorage.getItem("OrganizationID");

    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      submitStatus: true,
      form: {
        challanName: "",
        projectName: "",
        purchaseOrder: "",
        amount: "",
        comment: "",
        currency: "",
      },
      currencyList: [],
      priority: [
        { name: "High", value: "1" },
        { name: "Medium", value: "2" },
        { name: "Low", value: "3" },
        { name: "None", value: "4" },
      ],
      paymentMode: [
        { name: "Cash", value: "1" },
        { name: "Debit Card", value: "2" },
        { name: "Credit Card", value: "3" },
        { name: "UPI", value: "4" },
      ],
      purchaseOrderList: [],
      approverList: [
        {
          name: "abc",
          value: 1,
        },
      ],
      vendorList: [],
      projectList: [],
      categoryList: [
        {
          category_name: "abc",
          category_id: 1,
        },
      ],
      subcategoryList: [
        {
          category_name: "abc",
          category_id: 1,
        },
      ],
      dropzoneOptionsFiles: {
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        Object.keys(this.form).map((x) => {
          if (
            newVal[x] &&
            newVal[x] !== undefined &&
            String(newVal[x]).trim().length > 0
          ) {
          } else {
            console.log(x);
          }
        });
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data.filter((x) => {
              return x.vendor_isactive == 1;
            });
            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCurrencyList: function () {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.currencyList = data.data;
            console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPOList: function (id) {
      PurchaseOrderService.getApprovedPurchaseOrder(id)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.purchaseOrderList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projectList.push(x);
                }
              });
            } else {
              this.projectList = data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSuggestSelect: function () {},
    onProjectSelect: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      //this.getAllCategoryListByProject(this.project_id);
      this.getPOList(this.project_id);
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
            if (this.formMouId > 0) {
              let categoryArray = this.categoryList.filter((x) => {
                return x.category_id == this.form.categoryValue;
              });
              this.form.categoryValue = categoryArray[0];
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getAllUplodeFiles: function () {
      console.log("this.is :>> ", this.isDocuments);
      this.uploadFilePopup = true;
    },
    onChangeCheckUploadFile: function (doc) {
      window.open(doc);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },

    submitForm: function () {
      this.$vs.loading();

      setTimeout((x) => {
        // let payload = {
        //   ...this.form,
        //   optionItems: this.optionItems,
        //   milestoneItems: this.milestoneItems,
        // };

        //v2/challan?organization_id=29-POST

        let payload = {
          project_id: this.form.projectName
            ? this.form.projectName.project_id
            : 0,
          user_id: 249,
          challan_no: this.form.challanName,
          company_name: "-",
          currency: this.form.currency ? this.form.currency.currency_code : "",
          challan_amount: this.form.amount,
          challan_description: this.form.comment,
          challan_image: this.uploadedDocument,
          challan_active: 1,
          purchase_id: this.form.purchaseOrder
            ? this.form.purchaseOrder.purchase_id
            : 0,
          customFieldValues: [],
        };

        ChallanService.addChallan(payload)
          .then((response) => {
            const { data } = response;

            this.$vs.loading.close();
            if (data.Status == true) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
            }
            this.clearForm();
          })
          .catch((error) => {
            // this.$vs.notify({
            //   title: "Error!",
            //   text: error.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          });
      }, 1000);
    },

    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },
    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocument = response.data.image_path;
      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
      console.log("Uploaded Doc :", this.uploadedDocument);
    },
    clearFile: function () {
      this.uploadedDocument = "-";
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      this.form = {
        challanName: "",
        projectName: "",
        purchaseOrder: "",
        amount: "",
        comment: "",
        currency: "",
      };
      this.submitStatus = true;
      this.reason = "";
      this.uploadedDocument = "-";
      this.clearFile();
    },
  },
};
</script>

<style></style>
