import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addDCR(payload){
    return Api().post(`/v2/dcr?organization_id=${organization_id()}`, payload);
  },

  deleteDCR(payload){
    return Api().post(`/v2/dcr?organization_id=${organization_id()}`, payload);
  },

  getParticularsDates(project_id){
    return Api().get(`/v2/dcr/getAll/${project_id}?organization_id=${organization_id()}`);
  },

  getPreviousParticulars(project_id, dcr_date){
    return Api().get(`/v2/dcr/getDCRparticulars/${project_id}?organization_id=${organization_id()}&dcr_date=${dcr_date}`);
  },

  getAllParticularsExp(project_id, dcr_date, category_id, isDrop){
    return Api().get(`/v2/dcr/getDCRparticulars/${project_id}?organization_id=${organization_id()}&dcr_date=${dcr_date}&category_id=${category_id}&isDropdown=${isDrop}`);
  },
  getAllParticularsID(project_id, dcr_ids){
    return Api().get(`/v2/dcr/getDCRparticulars/${project_id}?organization_id=${organization_id()}&dcr_ids=${dcr_ids}`);
  },

  getAllParticulars(project_id, dcr_ids){
    return Api().get(`/v2/dcr/getDCRparticulars/${project_id}?organization_id=${organization_id()}&dcr_ids=${dcr_ids}`);
  },
  getDCRParticularsBYID(project_id, category_id, dcr_date){
    return Api().get(`/v2/dcr/getDCRParticularsBYID/${project_id}?organization_id=${organization_id()}&category_id=${category_id}&dcr_date=${dcr_date}`);
  },
 
  getDailyCostReport(project_id, dcr_date, category_id){
    return Api().get(`/v2/dcr/getAll/${project_id}?organization_id=${organization_id()}&dcr_date=${dcr_date}&category_id=${category_id}`);
  },

  getDailyCostReportExp(project_id, category_id){
    return Api().get(`/v2/dcr/getAll/${project_id}?organization_id=${organization_id()}&category_id=${category_id}`);
  },

  // REPORT

  getDCRReports(payload){
    return Api().get(`/v2/dcr/getDCRReport/${payload.project_id}?organization_id=${organization_id()}&from_date=${payload.date_From}&to_date=${payload.date_to}&category_id=${payload.category_id}`);
  },

  getDCRdetails(payload){
    return Api().get(`/v2/dcr/getDCRReport/${payload.project_id}?organization_id=${organization_id()}&dcr_date=${payload.dcr_date}`);
  },

  getAllReportParticulars(project_id, dcr_ids){
    return Api().get(`/v2/dcr/getReportDCRParticulars/${project_id}?organization_id=${organization_id()}&dcr_ids=${dcr_ids}`);
  },

  getAllReportParticularsEdit(payload){
    return Api().get(`/v2/dcr/getReportDCRParticulars/${payload.project_id}?organization_id=${organization_id()}&dcr_date=${payload.dcr_date}`);
  },

}
