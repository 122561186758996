<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Create Credit Note</h4>
          <form>
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Project</h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="NewForm.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Expense List</h6>
                <multiselect
                  label="expense_name"
                  class="w-full"
                  track-by="purchase_id"
                  @select="onExpenseList"
                  v-model="NewForm.expenseName"
                  name="Subcategory Name"
                  :options="expenseCreditList"
                  :searchable="true"
                  :disabled="expenseCreditList.length == 0"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.expense_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                    >{{ errors.first("Subcategory Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Expense Amount</h6>
                <vs-input
                  type="text"
                  name="Amount"
                  v-model="form.total"
                  disabled
                  class="w-full"
                />
              </div>
            </div>
            <!-- 1ST ROW -->
            <div v-if="false">
              <div class="row">
                <div class="col-4 mb-2 mx-auto">
                  <h6>Vendor</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.po_name"
                    disabled
                    class="w-full"
                  />
                </div>
                <template v-if="isCreditNote == 1">
                  <div class="col-4 mb-2">
                    <h6>Invoice Number</h6>
                    <vs-input
                      type="text"
                      name="Amount"
                      v-model="form.invoice_number"
                      disabled
                      class="w-full"
                    />
                  </div>

                  <div class="col-4 mb-2">
                    <h6>Invoice Date</h6>
                    <vs-input
                      type="text"
                      name="Amount"
                      v-model="form.invoice_date"
                      disabled
                      class="w-full"
                    />
                  </div>

                  <div class="col-4 mb-2">
                    <h6>Payment Due Date</h6>
                    <vs-input
                      type="text"
                      name="Amount"
                      v-model="form.payment_due_date"
                      disabled
                      class="w-full"
                    />
                  </div>
                </template>

                <div class="col-4 mb-2">
                  <h6>Category</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.parent_name"
                    disabled
                    class="w-full"
                  />
                </div>
              </div>

              <!-- 2ND ROW -->
              <div class="row">
                <div class="col-4 mb-2">
                  <h6>Subcategory</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.category_name"
                    disabled
                    class="w-full"
                  />
                </div>
                <div class="col-4 mb-2">
                  <h6>Country</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.country_name"
                    disabled
                    class="w-full"
                  />
                </div>
                <div class="col-4 mb-2">
                  <h6>On Behalf Of</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.user_name"
                    disabled
                    class="w-full"
                  />
                </div>
              </div>

              <!-- 3rd ROW -->
              <div class="row">
                <div class="col-4 mb-2">
                  <h6>Amount</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.total"
                    disabled
                    class="w-full"
                  />
                </div>
                <div class="col-4 mb-2">
                  <h6>Mode of payment</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.payment_mode_name"
                    disabled
                    class="w-full"
                  />
                </div>
                <div class="col-4 mb-2">
                  <h6>Priority</h6>
                  <vs-input
                    type="text"
                    name="Amount"
                    v-model="form.priority_name"
                    disabled
                    class="w-full"
                  />
                </div>
              </div>

              <div class="row" v-if="isCreditNote == 1">
                <div class="col-4 mb-2">
                  <h6>Tax</h6>
                  <div class="row">
                    <div class="col-6">
                      <vs-input
                        type="text"
                        name="Amount"
                        v-model="form.gst_name"
                        disabled
                        class="w-full"
                      />
                    </div>
                    <div class="col-6">
                      <vs-input
                        type="number"
                        name="Tax"
                        v-model="form.gst_amount"
                        class="w-full"
                        placeholder=""
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4 mb-2">
                  <h6>Total Amount</h6>
                  <vs-input
                    type="number"
                    name="GST"
                    v-model="form.total_amount"
                    class="w-full"
                    placeholder=""
                    disabled
                  />
                </div>
              </div>
            </div>

            <!-- 4th ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Credit Amount</h6>
                <vs-input
                  type="number"
                  name="Comment"
                  v-model="NewForm.credit_amount"
                  class="w-full mb-0"
                  rows="5"
                  @input="gstCalculateAmount"
                />
              </div>
              <div class="col-4 mb-2">
                <h6>Credit Invoice Number</h6>
                <vs-input
                  type="text"
                  name="Comment"
                  v-model="NewForm.credit_invoice_no"
                  class="w-full mb-0"
                  rows="5"
                />
              </div>
              <div class="col-4 mb-2">
                <h6>Credit Date</h6>
                <datepicker
                  placeholder="From Date"
                  format="dd-MM-yyyy"
                  v-model="NewForm.credit_date"
                ></datepicker>
              </div>
            </div>
            <div class="row" v-if="isCreditNote == 1">
              <div class="col-4 mb-2">
                <h6>Tax</h6>
                <div class="row">
                  <div class="col-6">
                    <multiselect
                      label="gst_name"
                      class="w-full"
                      track-by="gst_id"
                      v-model="NewForm.gst_id"
                      name="gst"
                      @select="gstCalculate"
                      :options="gstList"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove this value"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.gst_name
                      }}</template>
                    </multiselect>
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Priority')"
                        >{{ errors.first("Priority") }}</span
                      >
                    </p>
                  </div>
                  <div class="col-6">
                    <vs-input
                      type="number"
                      name="GST"
                      v-model="NewForm.gst_amount"
                      class="w-full"
                      placeholder=""
                    />
                    <p class="error-msg text-sm">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('GST Amount')"
                        >{{ errors.first("GST Amount") }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-4 mb-2">
                <h6>Total Amount</h6>
                <vs-input
                  type="number"
                  name="GST"
                  v-model="totalAmount"
                  class="w-full"
                  placeholder=""
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Currency</h6>
                <multiselect
                  label="currency_code"
                  class="w-full"
                  track-by="currency_id"
                  v-model="NewForm.currency"
                  name="Currency"
                  :options="currencyList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.currency_code
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Currency')"
                    >{{ errors.first("Currency") }}</span
                  >
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Reason</h6>
                <vs-textarea
                  name="Comment"
                  v-model="this.reason"
                  class="w-full mb-0"
                  rows="5"
                />
              </div>
              <div class="col-6 mb-2 doc-upload">
                <h6>Upload Document</h6>
                <vue-dropzone
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-error-multiple="vdropzoneError"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-added-files="vdropzoneFilesAdded"
                  @vdropzone-success-multiple="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                ></vue-dropzone>
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="feather icon-trash mt-2"
                ></vs-button>
              </div>
            </div>
          </form>

          <!-- BUTTONS :disabled="submitStatus" -->
          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              type="filled"
              :disabled="submitStatus"
              @click.prevent="submitForm"
              class="mr-3"
              >Submit</vs-button
            >

            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import projectService from "@/services/projectService.js";
import CashManagementService from "@/services/CashManagementService.js";
import CountryService from "@/services/countryService.js";
import UserService from "@/services/UserService.js";
import ExpenseService from "@/services/expenseService.js";
import MethodService from "@/services/methodService.js";

import CurrencyService from "@/services/currencyService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSuggest,
    VueSimpleSuggest,
  },

  beforeMount() {
    this.getProjectsList();
    //  this.getCountryList();
    //  this.getVendorList();
    this.getMasterList();
    this.getCurrencyList();

    this.organization_id = localStorage.getItem("OrganizationID");

    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      submitStatus: true,
      isAlreadyPaid: false,
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      toDateDisable: true,
      gstList: [],
      expenseCreditList: [],
      currencyList: [],
      reason: "",
      NewForm: {
        projectName: "",
        expenseName: "",

        credit_amount: 0,
        gst_amount: 0,
        gst_id: "",
        credit_invoice_no: "",
        credit_date: "",
        currency: "",
      },
      form: {
        po_name: "",
        invoice_number: "",
        invoice_date: "",
        payment_due_date: "",
        parent_name: "",
        category_name: "",
        country_name: "",
        user_name: "",
        total: "",
        payment_mode_name: "",
        priority_name: "",
        gst_name: "",
        gst_amount: "",
        total_amount: "",
        purchase_description: "",
        is_already_paid: "",
      },
      priority: [],
      paymentMode: [],
      countryList: [],
      approverList: [],
      vendorList: [],
      projectList: [],
      categoryList: [],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
        //chunking: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      uploadedDocumentArray: [],
    };
  },
  watch: {
    NewForm: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.NewForm).length;
        Object.keys(this.NewForm).map((x) => {
          if (
            newVal[x] &&
            newVal[x] !== undefined &&
            String(newVal[x]).trim().length > 0
          ) {
          } else {
            console.log("fdsfds", x);
          }
        });
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    totalAmount() {
      let amount =
        this.NewForm.credit_amount != ""
          ? parseFloat(this.NewForm.credit_amount).toFixed(2)
          : 0;
      return parseFloat(this.NewForm.gst_amount) + parseFloat(amount);
    },
    isCreditNote() {
      return window.localStorage.getItem("isCreditNote");
    },
  },
  mounted() {
    if (this.isCreditNote == 0) {
      this.form.invoice_number = "1";
      this.form.payment_due_date = "1";
      this.form.invoice_date = "1";
      this.form.gst_id = "1";
      this.form.gst_amount = "1";
    }
  },
  beforeDestroy() {},
  methods: {
    getCurrencyList: function () {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.currencyList = data.data;
            console.log(this.currencyList, "Currency");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onExpenseList(selected) {
      this.getExpenseById(selected.purchase_id);
    },
    getExpenseById: function (purchase_id) {
      ExpenseService.getSingleExpense(purchase_id)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            Object.keys(data.data).map((x) => {
              this.form[x] = data.data[x];
            });
            this.isAlreadyPaid =
              data.data["is_already_paid"] == 0 ? false : true;
            //  console.log(data.data,"fdsfdsfds")
            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    gstCalculateAmount() {
      if (this.NewForm.gst_id != "") {
        this.gstCalculate(this.NewForm.gst_id);
      }
    },
    gstCalculate(selected) {
      let amount =
        this.NewForm.credit_amount != ""
          ? parseFloat(this.NewForm.credit_amount).toFixed(2)
          : 0;
      let totalAmount =
        (parseFloat(selected.gst_percentage).toFixed(2) * amount) / 100;
      console.log(totalAmount);
      this.NewForm.gst_amount = parseFloat(totalAmount).toFixed(2);
    },
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      console.log(this.endDisabledDates);
      this.form.payment_due_date = new Date($event);
      this.toDateDisable = false;
    },
    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            // this.priority = data.data.priority;
            // this.paymentMode = data.data.payment_mode;
            this.gstList = data.data.gst;
            //  console.log(data.data,"fdsfdsfds")
            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data;
            // console.log(this.currencyList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMapList: function (id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.approverList = data.data;
            // console.log(this.ApproverList);
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getCountryList: function () {
      ///v2/country

      CountryService.getCountry()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.countryList = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projectList.push(x);
                }
              });
            } else {
              this.projectList = data.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },
    onProjectSelect: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      Object.keys(this.form).map((x) => {
        this.form[x] = "";
      });

      this.expenseCreditList = [];
      this.NewForm.expenseName = "";
      this.showCreditExpenseList(selectedOption.project_id);

      // this.getAllCategoryListByProject(this.project_id);
      // this.getMapList(this.project_id);
    },
    showCreditExpenseList(project_id) {
      ExpenseService.showCreditExpenseList(project_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.expenseCreditList = data.data;
            console.log(data.data);
          } else {
            this.expenseCreditList = [];
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getAllUplodeFiles: function () {
      console.log("this.is :>> ", this.isDocuments);
      this.uploadFilePopup = true;
    },
    onChangeCheckUploadFile: function (doc) {
      window.open(doc);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },

    submitForm: function () {
      if (
        parseFloat(this.form.total) < parseFloat(this.NewForm.credit_amount)
      ) {
        this.$vs.notify({
          title: "Error!",
          text: "Credit Amount is less than Total Amount",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });

        return false;
      }
      this.$vs.loading();

      setTimeout((x) => {
        let payload = {
          ...this.NewForm,
        };
        payload["purchase_id"] = this.NewForm.expenseName.purchase_id;
        payload["currency_id"] = this.NewForm.currency.currency_code;
        payload["gst_id"] = this.NewForm.gst_id.gst_id;
        payload["total_amount"] = this.totalAmount;
        payload["credit_image"] = this.uploadedDocument;
        payload["credit_reason"] = this.reason;

        ExpenseService.addCreditNote(payload)
          .then((response) => {
            const { data } = response;
            this.$vs.loading.close();
            if (data.Status == true) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
            }
            this.clearForm();
          })
          .catch((error) => {
            // this.$vs.notify({
            //   title: "Error!",
            //   text: error.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          });
      }, 1000);
    },

    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },
    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
      console.log(this.uploadedDocument, "te");
    },
    clearFile: function () {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
      for (let i = 0; i < response.length; i++) {
        this.selectedFilesUUID.push(response[i].upload.uuid);
        //console.log(response[i].upload.uuid);
      }
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    vremoved: function (file) {
      // console.log("file, xhr, error :", file.upload);
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        //   this.$refs.myVueDropzoneFile.removeAllFiles();
        this.fileUpload.deleteButtonStatus = false;
        //   this.$refs.myVueDropzoneFile.enable();
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
      console.log(
        this.selectedFilesUUID,
        "==",
        this.uploadedDocumentArray,
        "==",
        this.uploadedDocument
      );
    },
    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      Object.keys(this.form).map((x) => {
        this.form[x] = "";
      });
      Object.keys(this.NewForm).map((x) => {
        this.NewForm[x] = "";
      });
      this.expenseCreditList = [];
      this.submitStatus = true;
      this.reason = "";
      this.uploadedDocument = "-";
      this.clearFile();
      this.submitStatus = true;
    },
  },
};
</script>

<style></style>
