<template>
  <div id="dashboard-analytics">
    <vx-card class="form-title mb-6 vx-card-np" title="User Expenses">
      <div class="vx-row mx-0">
        <div class="vx-col w-full">
          <vs-tabs>
            <!-- VIEW PENDING -->
            <vs-tab label="View Pending" icon-pack="feather" icon="icon-box">
              <div class="tab-text">
                <div>
                  <pending-list></pending-list>
                </div>
              </div>
            </vs-tab>
            <!-- VIEW APPROVED -->
            <vs-tab label="View Approved" icon-pack="feather" icon="icon-box">
              <div>
                <approved-list></approved-list>
              </div>
            </vs-tab>
            <!-- VIEW REJECTED -->
            <vs-tab label="View Rejected" icon-pack="feather" icon="icon-box">
              <div>
                <rejected-list></rejected-list>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import pendingList from "@/views/apps/expenses/pendingList.vue";
import approvedList from "@/views/apps/expenses/approvedList.vue";
import rejectedList from "@/views/apps/expenses/rejectedList.vue";

export default {
  data() {
    return {
      isActive: false,
      tabIndex: 0,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false
    };
  },
  methods: {},
  components: {
    pendingList,
    approvedList,
    rejectedList
  },
  mounted() {}
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
