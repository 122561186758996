import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addShootLocation(payload) {
    return Api().post(
      `/v2/shootLocation?organization_id=${organization_id()}`,
      payload
    );
  },
  editShootLocation(payload, id) {
    return Api().put(
      `/v2/shootLocation/${id}?organization_id=${organization_id()}`,
      payload
    );
  },

  getAllShootLocation(filters, sort) {
    //  if (filters && filters.hasOwnProperty('page')) {
    //    return Api().get(`/v2/shootLocation/page/${filters.page}?organization_id=${organization_id()}&isDashboard=1`);
    //    } else
    return Api().get(
      `v2/shootLocation?organization_id=${organization_id()}&isDashboard=1`
    );
  },
  getActiveShootLocation(filters, sort) {
    //  if (filters && filters.hasOwnProperty('page')) {
    //    return Api().get(`/v2/shootLocation/page/${filters.page}?organization_id=${organization_id()}&isDashboard=1`);
    //    } else
    return Api().get(`v2/shootLocation?organization_id=${organization_id()}`);
  }

  // getDepartment(id, archive) {
  //   if (!archive) {
  //     return Api().get(`/v2/department/${id}?organization_id=${organization_id()}`);
  //   } else {
  //     return Api().get(`/v2/department/${id}?${archive}&organization_id=${organization_id()}`);
  //   }
  // },
};
