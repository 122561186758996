<template>
  <div id="dashboard-analytics">
    <vx-card class="form-title mb-6 vx-card-np" title="Select Form Type">
      <div class="vx-row mx-0">
        <div class="vx-col w-full">
          <vs-tabs>
            <!-- CASH REQUEST FORM -->
            <vs-tab
              label="Create Advance Request"
              icon-pack="feather"
              icon="icon-box"
              v-if="isAdvanced"
            >
              <div class="tab-text">
                <div>
                  <cash-request-form></cash-request-form>
                </div>
              </div>
            </vs-tab>
            <!-- CREATE PURCHASE ORDER -->
            <vs-tab
              label="Create Purchase Order"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <purchase-order-form></purchase-order-form>
              </div>
            </vs-tab>
            <!-- CREATE EXPENSE -->
            <vs-tab label="Create Expense" icon-pack="feather" icon="icon-box">
              <div>
                <expense-form></expense-form>
              </div>
            </vs-tab>
            <!-- CREATE DELIVERY CHALLAN -->
            <vs-tab
              label="Create Delivery Challan"
              icon-pack="feather"
              icon="icon-box"
            >
              <div>
                <delivery-challan-form></delivery-challan-form>
              </div>
            </vs-tab>
            <!-- CREATE CREDIT NOTE -->
            <vs-tab
              label="Create Credit Note"
              icon-pack="feather"
              icon="icon-box"
              v-if="isCreditNote == 1"
            >
              <div>
                <Credit-Note-Form></Credit-Note-Form>
              </div>
            </vs-tab>

            <!-- CREATE DCR -->
            <vs-tab label="Create DCR" icon-pack="feather" icon="icon-box" v-if="isDCR == 1">
              <div>
                <dpr-create></dpr-create>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import cashRequestForm from "@/views/apps/expenses/cashRequestForm.vue";
import purchaseOrderForm from "@/views/apps/expenses/PurchaseOrderForm.vue";
import deliveryChallanForm from "@/views/apps/expenses/deliveryChallanForm.vue";
import expenseForm from "@/views/apps/expenses/expenseForm.vue";
import CreditNoteForm from "@/views/apps/expenses/CreditNoteForm.vue";
import dprCreate from "@/views/apps/DPR/dprCreate.vue";

export default {
  data() {
    return {
      isActive: false,
      tabIndex: 0,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
    };
  },
  methods: {},
  computed: {
    isAdvanced() {
      return window.localStorage.getItem("isAdvanced") == 1;
    },
    isCreditNote() {
      return window.localStorage.getItem("isCreditNote");
    },
    isDCR() {
      return window.localStorage.getItem("dcr");
    },
  },
  components: {
    cashRequestForm,
    purchaseOrderForm,
    deliveryChallanForm,
    expenseForm,
    CreditNoteForm,
    dprCreate,
  },
  mounted() {},
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
