import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCountry(payload) {
    return Api().post(`/v2/country/master/addCountry?organization_id=${organization_id()}`, payload);
  },
  editCountry(payload, id) {
    return Api().post(`/v2/country?organization_id=${organization_id()}`, payload);
  },

  getAllCountry(filters, sort) {
    if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/country?organization_id=${organization_id()}&isDashboard=1&page_no=${filters.page}`);
    } else {
      return Api().get(`/v2/country?organization_id=${organization_id()}&isDashboard=1`);

    }
  },
  getAllCity(filters, sort) {
    return Api().get(`/v2/city?organization_id=${organization_id()}`);
  },

  getCityByCountry(id) {
    return Api().get(`/v2/city/country/${id}?organization_id=${organization_id()}`);
  },
  getCountry() {
    return Api().get(`/v2/country?organization_id=${organization_id()}`);
  }
};
