var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.params.data.purchase_status == 1)?[_c('a',{attrs:{"target":"_blank","href":_vm.params.data.purchase_status == 1
          ? _vm.URL_BASE +
            '/v2/purchase/download/' +
            _vm.purchase_id +
            '?organization_id=' +
            _vm.organization_id +
            '&token=' +
            _vm.temp_token
          : ''},on:{"click":function($event){_vm.params.data.purchase_status == 1 ? '' : 'return false'}}},[_c('span',{staticClass:"text-center table-edit-icon",on:{"click":function($event){return _vm.downloadPo()}}},[_c('i',{staticClass:"text-center material-icons"},[_vm._v("get_app")])])])]:[_c('span',{staticClass:"text-center table-edit-icon view-icon-disabled",on:{"click":function($event){return _vm.downloadPo()}}},[_c('i',{staticClass:"text-center material-icons"},[_vm._v("get_app")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }