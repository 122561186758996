import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCurrency(payload) {
    return Api().post(`/v2/currency/master/addCurrency?organization_id=${organization_id()}`, payload);
  },

  editCurrency(payload, id) {
    return Api().post(`/v2/currency?organization_id=${organization_id()}`, payload);
  },

  getAllCurrency(filters) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/currency?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/currency?organization_id=${organization_id()}&isActive=1&page_no=${filters.page}`);
    } else return Api().get(`/v2/currency?organization_id=${organization_id()}`);
  },

  getAllCurrencyList(filters) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/currency?archive=1&isDashboard=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/currency/page/${filters.page}?isDashboard=1&organization_id=${organization_id()}`);
    } else return Api().get(`/v2/currency?isDashboard=1&organization_id=${organization_id()}`);
  },
  getAllInactiveCurrencyList(filters) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/currency?archive=1&isInactive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/currency/page/${filters.page}?isInactive=1&organization_id=${organization_id()}`);
    } else return Api().get(`/v2/currency?isInactive=1&organization_id=${organization_id()}`);
  },
  changeCurrencyStatus(payload) {
    return Api().put(`v2/currency/delete/multiple?organization_id=${organization_id()}`, payload)
  },
  getCurrency() {
    return Api().get(`/v2/currency?organization_id=${organization_id()}`);
  },
};
