
<template>
  <div>
    <!-- v-if="this.params.data.budget_id == 0"  -->
    <span
      class="text-center table-edit-icon"
      v-if="this.params.data.budget_id == 0"
      @click="moveToBudget"
    >
      <i class="text-center material-icons">move_to_inbox</i>
    </span>
    <span class="text-center table-edit-icon view-icon-disabled" v-else>
      <i class="text-center material-icons">move_to_inbox</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA'+params.data.inventory_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Move To Budget"
        :active.sync="popupActive"
      >
        <div class>
          <!-- <h5 class="text-center ">Do you want to move to Budget?</h5> -->
          <!-- SUBMIT BUTTON -->

          <div class="vx-col w-full">
            <div class="mx-0 row mb-6">
              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Expense Name:</p>
                  <div class>
                    <h5>{{purchaseData.po_name}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">User</p>
                  <div class>
                    <h5 class="text-break">{{purchaseData.user_name}}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-0 row mb-6">
              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Project</p>
                  <div class>
                    <h5>{{purchaseData.project_name}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">VHC Code</p>
                  <div class>
                    <h5 class="text-break">{{purchaseData.VHC_code}}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-0 row mb-6">
              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Country</p>
                  <div class>
                    <h5>{{purchaseData.country_name}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">City</p>
                  <div class>
                    <h5 class="text-break">{{purchaseData.city_name}}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-0 row mb-6">
              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Price</p>
                  <div class>
                    <h5>{{purchaseData.price}}</h5>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div>
                  <p class="text-muted small mb-1">Description</p>
                  <div class>
                    <h5 class="text-break">{{purchaseData.purchase_description}}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row mb-5">
              <div class="col-md-4 text-right align-self-center">
                <h5 class="mb-0">Category</h5>
              </div>
              <div class="col-7">
                <!-- Start ************* -->
                <div>
                  <multiselect
                    v-model="categoryValue"
                    track-by="category_id"
                    label="category_name"
                    @input="({category_id})=> this.form.category_id = category_id"
                    :options="categories"
                    name="Category Name"
                    placeholder="Select Category"
                    @select="onSuggestSelect"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.category_name }}</template>
                  </multiselect>

                  <!-- <multiselect
                    v-model="categoryValue"
                    track-by="category_id"
                    label="category_name"
                    :options="categories"
                    name="Category Name"
                    placeholder="Select Category"
                    @select="onSuggestSelect"
                    :searchable="true"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.category_name }}</template>
                  </multiselect>-->
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Category Name')"
                    >{{ errors.first('Category Name') }}</span>
                  </p>
                </div>
                <!-- ************* End -->
              </div>
            </div>

            <div class="vx-row mb-6">
              <div class="col-md-4 text-right align-self-center">
                <h5 class="mb-0">Subcategory</h5>
              </div>
              <div class="col-7">
                <!-- Start ************* -->
                <div>
                  <multiselect
                    v-model="subcategoryValue"
                    track-by="category_id"
                    @input="({category_id})=> this.form.category_id = category_id"
                    label="category_name"
                    :options="subcategories"
                    name="subcategory Name"
                    placeholder="Select Category"
                    :searchable="true"
                    @select="onSuggestSelectSub"
                    :allow-empty="false"
                    open-direction="bottom"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.category_name }}</template>
                  </multiselect>
                  <p class="error-msg">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('subcategory Name')"
                    >{{ errors.first('subcategory Name') }}</span>
                  </p>
                </div>
                <!-- ************* End -->
              </div>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                v-if="isUpdated"
                color="success"
                type="filled"
                @click="onChangeUpdateExp($event)"
                class="mr-3"
              >update</vs-button>

              <vs-button
                v-if="!isUpdated"
                color="success"
                type="filled"
                @click="onChangeMoveToBudget()"
                class="mr-3"
              >Move</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">cancel</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
// import departmentService from "@/services/departmentService.js";
import InventoryService from "@/services/inventoryService.js";
import CategoryService from "@/services/categoryService.js";
import BudgetService from "@/services/budgetService.js";
import purchaseOrderService from "@/services/purchaseOrderService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      projectID: null,
      category_name: "",
      isUpdated: false,
      categories: [],
      subcategories: [],
      categoryValue: null,
      subcategoryValue: null,
      form: {
        parent_id: "",
        category_id: "",
      },

      // copy_inventory_active: null
    };
  },
  beforeMount() {
    // this.form = this.params.data
    this.form = this.params.data;
    this.purchaseData = this.params.data;
    // this.categories.category_id = this.params.data.
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalA" + this.params.data.purchase_id
    );
    // _id.setAttribute("style", "display: none;");
  },
  methods: {
    moveToBudget: function () {
      this.popupActive = true;
      this.projectID = this.params.data.project_id;
      this.getCategoryList();
    },
    onChangeUpdateExp: function (e, value) {
      this.$vs.loading();
      let ID = this.params.data.purchase_id;
      let payload = {
        project_id: this.purchaseData.project_id,
        user_id: this.purchaseData.user_id,
        po_name: this.purchaseData.po_name,
        item_name: this.purchaseData.item_name,
        company_name: this.purchaseData.company_name,
        company_address: this.purchaseData.company_address,
        price: this.purchaseData.price,
        currency: this.purchaseData.currency,
        VHC_code: this.purchaseData.VHC_code,
        sgst: this.purchaseData.sgst,
        cgst: this.purchaseData.cgst,
        total: this.purchaseData.total,
        purchase_image: this.purchaseData.purchase_image,
        category_id: this.form.category_id,
        parent_id: this.form.parent_id,
        city_id: this.purchaseData.city_id,
        country_id: this.purchaseData.country_id,
        budget_id: this.purchaseData.budget_id,
        purchase_description: this.purchaseData.purchase_description,
        purchase_status: this.purchaseData.purchase_status,
        approved_percentage: this.purchaseData.approved_percentage,
        purchase_active: this.purchaseData.purchase_active,
      };
      purchaseOrderService
        .editPurchaseOrder(payload, ID)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.$vs.loading.close();
            this.popupActive = false;
            this.$forceUpdate();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    privStatus: function () {
      this.popupActive = false;
      // this.form.inventory_active = this.copy_inventory_active;
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(selected.category_id || null);
      this.isUpdated = true;
    },
    onSuggestSelectSub: function () {
      this.isUpdated = true;
    },
    onChangeMoveToBudget: function () {
      let payload = [];
      let moveData = {
        purchase_id: this.params.data.purchase_id,
      };
      payload.push(moveData);
      BudgetService.moveToBudget(payload)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCategoryList: function () {
      let ID = this.projectID;
      CategoryService.getProjectMainCategories(ID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.categories = data.data;
            this.categories.map((x) => {
              if (this.params.data.parent_id === x.category_id) {
                this.categoryValue = x;
                this.form.parent_id = x.category_id;
                this.getSubcategoryList(x.category_id);
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubcategoryList: function (ID) {
      CategoryService.getSubCategoriesByParentCategory(ID, this.projectID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategories = data.data;
            this.subcategories.map((x) => {
              if (this.params.data.category_id === x.category_id) {
                this.subcategoryValue = x;
                this.form.category_id = x.category_id;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
});
</script>