<template>
  <div>
    <span
      class="text-center table-edit-icon"
      @click="getPreview()"
      @mouseover="getExpenseSettings()"
    >
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalView' + params.data.purchase_id"
        title="Expense Details"
        :active.sync="popupActive"
      >
        <div
          class="vx-col w-full"
          v-if="
            expenseSettings.showInvoiceNumber && expenseSettings.showGstPopup
          "
        >
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Expense Name:</p>
                <div class>
                  <h5>{{ params.data.po_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Invoice Number:</p>
                <div class>
                  <h5 class="text-break">{{ params.data.invoice_number }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Currency:</p>
                <div class>
                  <h5>{{ params.data.currency }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Payment Mode:</p>
                <div class>
                  <h5>{{ params.data.payment_mode_name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Invoice Date:</p>
                <div class>
                  <h5>{{ params.data.invoice_date }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">GST Name:</p>
                <div class>
                  <h5>{{ params.data.gst_name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Tax Amount:</p>
                <div class>
                  <h5>{{ params.data.gst_amount }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Total Amount</p>
                <div class>
                  <h5>
                    {{
                      params.data.total_amount
                        ? params.data.total_amount.toLocaleString("en-IN")
                        : ""
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Set Name:</p>
                <div class>
                  <h5>{{ params.data.location_name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">City:</p>
                <div class>
                  <h5>{{ userDetail.city_name }}</h5>
                </div>
              </div>
            </div>
          </div>-->
        </div>

        <div class="vx-col w-full" v-else>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Expense Name:</p>
                <div class>
                  <h5>{{ params.data.po_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Payment Mode:</p>
                <div class>
                  <h5>{{ params.data.payment_mode_name }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Currency:</p>
                <div class>
                  <h5>{{ params.data.currency }}</h5>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Total Amount</p>
                <div class>
                  <h5>
                    {{
                      params.data.total_amount
                        ? params.data.total_amount.toLocaleString("en-IN")
                        : ""
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">Set Name:</p>
                <div class>
                  <h5>{{ params.data.location_name }}</h5>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="mx-0 row mb-6">
            <div class="col-6">
              <div>
                <p class="text-muted small mb-1">City:</p>
                <div class>
                  <h5>{{ userDetail.city_name }}</h5>
                </div>
              </div>
            </div>
          </div>-->
        </div>

        <div
          class="vx-col w-full border-bottom mb-6"
          v-if="
            params.data.credit_invoice_no != '' &&
            params.data.credit_invoice_no != null
          "
        >
          <h3 class="text-center mb-4">-Credit Note Details-</h3>
          <div class="mx-0 row mb-6">
            <div class="col-6">
              <p class="text-muted small mb-1">Credit Amount :</p>

              <div class>
                <h5>{{ params.data.credit_amount }}</h5>
              </div>
            </div>

            <div class="col-6">
              <p class="text-muted small mb-1">Credit Invoice No:</p>
              <div class>
                <h5>{{ params.data.credit_invoice_no }}</h5>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UserService from "@/services/UserService.js";
import { error } from "util";
import moment from "moment";
export default Vue.extend({
  data() {
    return {
      userDetail: [],
      params: null,
      singleUserId: "",
      userEmpDetails: [],
      popupActive: false,
      expenseSettings: [],
      organization_id: window.localStorage.getItem("OrganizationID"),
      //   project_id: ""
    };
  },
  components: {
    moment,
  },

  beforeMount: function () {
    // this.value = this.params.data;
    // this.singleUserId = this.value.purchase_id;
    this.purchase_id = this.params.value;
    // this.getExpenseSettings();
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.purchase_id
    );
    _id.setAttribute("style", "display: none;");
  },
  filters: {
    moment: function (date) {
      if (date == "" || date == null) {
        return "-";
      } else {
        return moment(date).format("DD-MM-YYYY");
      }
    },
  },

  methods: {
    getPreview: function () {
      this.popupActive = true;
    },
    getExpenseSettings: function () {
      UserService.getProfileInfoViaOrg(this.organization_id)
        .then((response) => {
          const { data } = response;
          // console.log("Get Profile Info Data", data);
          if (data && data.Status) {
            this.expenseSettings = data.data;
            console.log("Get Profile Info Data", this.expenseSettings);
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        });
    },
  },
});
</script>

<style>
</style>
