import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getExpense(payload, filters) {
    // return Api().post(`/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id}`, payload);
    if (filters && filters.hasOwnProperty("page")) {
      return Api().post(
        `/v2/purchase/expense/getExpenseByFilters?page_no=${
          filters.page
        }&organization_id=${organization_id()}&type=${payload.type}`,
        payload
      );
    } else {
      return Api().post(
        `/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id()}&type=0`,
        payload
      );
    }
  },
  getAllExpense(payload) {
    let permisProj = payload.permissionProject ? payload.permissionProject : "";
    return Api().get(
      `/v2/purchase?organization_id=${organization_id()}&owner=0&purchaseStatus=${
        payload.purchaseStatus
      }&permissionProject=${permisProj}`
    );
  },
  getSingleExpense(purchase_id) {
    return Api().get(
      `/v2/purchase/${purchase_id}?organization_id=${organization_id()}`
    );
  },
  getExpenseByFilter(payload, filters) {
    // if (payload && payload.hasOwnProperty('page_no')) {
    // return Api().post(`/v2/purchase/expense/getExpenseByFilters?page_no=${payload.page_no}&organization_id=${organization_id()}&type=${payload.type}`, payload);
    // } else {
    return Api().post(
      `/v2/purchase/expense/getExpenseByFilters?organization_id=${organization_id()}`,
      payload
    );
    // }
  },
  addExpense(payload, filters) {
    return Api().post(
      `/v2/purchase?organization_id=${organization_id()}`,
      payload
    );
  },
  addCreditNote(payload, filters) {
    return Api().post(
      `/v2/creditNote?organization_id=${organization_id()}`,
      payload
    );
  },
  showCreditExpenseList(project_id) {
    return Api().get(
      `/v2/purchase/creditNote/${project_id}?organization_id=${organization_id()}`
    );
  }
};
