<template>
  <div>
    <span
      class="text-center table-edit-icon"
      @click="showModal"
      v-if="
        params.data.hasOwnProperty('purchase_image') &&
        params.data.purchase_image != '-'
      "
    >
      <i class="text-center material-icons">info</i>
    </span>
    <span class="text-center">No Images</span>
    <!-- POPUP -->
    <div class>
      <vs-popup
        style="z-index: 999999 !important"
        :id="'popmodal' + params.data.purchase_image"
        title="Expense"
        :active.sync="popupActive"
      >
        <!-- <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
          <swiper-slide :key="imageKey" v-for="(image,imageKey) in images">
            <div class="swiper-zoom-container">
              <img class="responsive text-center" :src="image+'?token='+tempToken" alt="banner" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>-->

        <!-- Swiper -->
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-slide"
            :key="imageKey"
            v-for="(image, imageKey) in images"
          >
            <div
              class="swiper-zoom-container"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <img
                style="height: 60vh; width: auto"
                :src="image + '?token=' + tempToken"
                alt="banner"
              />
            </div>

            <!-- <div class="swiper-zoom-container" v-else-if="false">
              <vue-pdf-viewer height="500px" :url="image">
                  </vue-pdf-viewer>
            </div>-->
            <div class="row col-12" v-else>
              <vs-button
                class="px-4 text-center"
                @click.native="downloadPdf(image, tempToken)"
                style="margin: auto"
              >
                <span class="d-inline-flex pr-5 text-white"
                  >Click here to preview</span
                >
                <img
                  src="@/assets/images/pdf-icon.png"
                  v-if="image.split('.').slice(-1)[0] == 'pdf'"
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
                <img
                  src="@/assets/images/excelLogo.png"
                  v-else
                  class="d-inline-flex"
                  height="40"
                  width="40"
                />
              </vs-button>
            </div>
            <div
              class="mx-auto text-center my-2"
              v-if="extensions.indexOf(image.split('.').slice(-1)[0]) >= 0"
            >
              <button
                @click="downloadPdf(image)"
                class="vs-component vs-button vs-button-primary vs-button-filled"
              >
                Download
              </button>

              <!-- <a
                href="#"
                @click.prevent="
                  downloadItem({
                    url: image,
                    label: 'example.pdf',
                  })
                "
              >
                download
              </a> -->
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import departmentService from "@/services/departmentService.js";
import companyService from "@/services/companyService.js";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";
// import VuePDFViewer from 'vue-pdf-viewer'
export default Vue.extend({
  components: {
    swiper,
    swiperSlide,
    // 'vue-pdf-viewer': VuePDFViewer
  },
  data() {
    return {
      tempToken: window.localStorage.getItem("TempToken"),
      images: [],
      popupActive: false,
      extensions: ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"],
      params: null,
      value: null,
      count: 0,
      swiperOption: {
        slidesPerView: 1,
        autoHeight: true,
        spaceBetween: 30,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.purchase_image
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    downloadPdf(img, tempToken) {
      console.log(img + "?token=" + window.localStorage.getItem("TempToken"));

      window.open(img + "?token=" + window.localStorage.getItem("TempToken"));
    },
    async downloadItem({ url, label }) {
      let finalUrl = url + "?token=" + window.localStorage.getItem("TempToken");
      console.log("aksjfcbkjqewkfqwd", finalUrl);
      const response = await axios.get(finalUrl, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    showModal() {
      this.popupActive = true;
      let obj = {
        budget_id: this.params.data.budget_id,
        show: false,
        budget_code: this.params.data.budget_code,
        purchase_id: this.params.data.purchase_id,
      };
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.purchase_id + "Log",
        obj
      );
    },
    onClickCloseButton() {
      let obj = {
        budget_id: this.params.data.budget_id,
        purchase_id: this.params.data.purchase_id,
        show: true,
        budget_code: this.params.data.budget_code,
      };
      console.log(obj);
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_code + "Code",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.budget_id + "Category",
        obj
      );
      eventBus.$emit(
        "showPopupExpense" + this.params.data.purchase_id + "Log",
        obj
      );
    },
    submitForm() {},
  },

  mounted() {
    console.log(this.params);
    this.images =
      this.params.value.length > 1 ? this.params.value.split(",") : [];

    const next = this.$children[0].$refs.btnclose;
    next.$el.addEventListener("click", this.onClickCloseButton, false);
  },
});
</script>
<style>
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}
</style>
