<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title">
          <h4>Advance Request</h4>
          <form>
            <!-- 1ST ROW -->
            <div class="row">
              <div class="col-4 mb-2 mx-auto">
                <h6>Vendor</h6>
                <vue-simple-suggest
                  :min-length="0"
                  v-model="form.vendorName"
                  :list="vendorList"
                  display-attribute="vendor_name"
                  value-attribute="vendor_id"
                  :filter-by-query="true"
                  name="Vendor Name"
                ></vue-simple-suggest>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vendor Name')"
                    >{{ errors.first("Vendor Name") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Project</h6>
                <multiselect
                  v-model="form.projectName"
                  track-by="project_id"
                  label="project_name"
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  @select="categoryList"
                  :allow-empty="false"
                  open-direction="bottom"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.project_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Category</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoriesByProject"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                    >{{ errors.first("Category Name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 2ND ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Subcategory</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.category_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                    >{{ errors.first("Subcategory Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Set Name</h6>
                <multiselect
                  label="location_name"
                  class="w-full"
                  track-by="location_id"
                  v-model="form.shootLocation"
                  name="location_name"
                  :options="shootLocationList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('location_name')"
                    >{{ errors.first("location_name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Currency</h6>
                <multiselect
                  label="currency_code"
                  class="w-full"
                  track-by="currency_id"
                  v-model="form.currency"
                  name="Currency"
                  :options="currencyList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.currency_code
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Currency')"
                    >{{ errors.first("Currency") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Amount</h6>
                <vs-input
                  v-validate="'max:12'"
                  type="number"
                  name="Amount"
                  v-model="form.amount"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Amount')"
                    >{{ errors.first("Amount") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 3ND ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Request by</h6>
                <multiselect
                  label="user_name"
                  class="w-full"
                  track-by="user_name"
                  v-model="form.req_user"
                  name="Request by"
                  :options="BehalfOfList"
                  @select="onSelectBehalf"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.user_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Request by')"
                    >{{ errors.first("Request by") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Approver</h6>
                <multiselect
                  label="user_name"
                  class="w-full"
                  track-by="user_name"
                  v-model="form.approver_id"
                  name="Approver Name"
                  :options="ApproverList"
                  :searchable="true"
                  :disabled="form.req_user == '' ? true : false"
                  :allow-empty="false"
                  :multiple="true"
                  :close-on-select="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.user_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Approver Name')"
                    >{{ errors.first("Approver Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Mode of payment</h6>
                <multiselect
                  label="payment_mode_name"
                  class="w-full"
                  track-by="payment_mode_id"
                  v-model="form.modeOfPayment"
                  name="Mode of payment"
                  :options="paymentMode"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{
                    option.payment_mode_name
                  }}</template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Mode of payment')"
                    >{{ errors.first("Mode of payment") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 3RD ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Comment</h6>
                <vs-textarea
                  name="Comment"
                  v-model="form.comment"
                  class="w-full mb-0"
                  rows="5"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Comment')"
                    >{{ errors.first("Comment") }}</span
                  >
                </p>
              </div>
              <div class="col-6 mb-2 doc-upload">
                <h6>Upload Document</h6>
                <vue-dropzone
                  @vdropzone-removed-file="vremoved"
                  @vdropzone-error-multiple="vdropzoneError"
                  @vdropzone-complete-multiple="vdropzoneComplete"
                  @vdropzone-added-files="vdropzoneFilesAdded"
                  @vdropzone-success-multiple="vdropzoneSuccessFile"
                  ref="myVueDropzoneFile"
                  id="dropzone"
                  class="rounded"
                  :options="dropzoneOptionsFiles"
                ></vue-dropzone>
                <vs-button
                  v-if="fileUpload.deleteButtonStatus"
                  type="filled"
                  @click.prevent="clearFile()"
                  class="feather icon-trash mt-2"
                />
              </div>
            </div>
          </form>

          <!-- BUTTONS -->
          <div class="vx-col mx-auto text-center mt-5">
            <vs-button
              :disabled="submitStatus"
              type="filled"
              @click.prevent="submitForm"
              class="mr-3"
              >Submit</vs-button
            >

            <vs-button
              color="warning"
              type="border"
              class
              @click.prevent="clearForm"
              >Reset</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";
import Datepicker from "vuejs-datepicker";
import MethodService from "@/services/methodService.js";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";

import projectService from "@/services/projectService.js";
import CashManagementService from "@/services/CashManagementService.js";
import CurrencyService from "@/services/currencyService.js";
import UserService from "@/services/UserService.js";
import ShootLocationService from "@/services/shootLocationService.js";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    vueDropzone: vue2Dropzone,
    VueSuggest,
    VueSimpleSuggest,
  },
  beforeMount() {
    this.getProjectsList();
    this.getCurrencyList();
    this.getShootLocationList();
    this.getVendorList();
    this.getMasterList();
    this.organization_id = localStorage.getItem("OrganizationID");

    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      currencyList: [],
      shootLocationList: [],
      ApproverList: [],
      BehalfOfList: [],
      projects: [],
      paymentMode: [],
      categoriesByProject: [],
      organization_id: "",

      submitStatus: true,
      form: {
        vendorName: "",
        categoryValue: "",
        subcategoryValue: "",
        currency: "",
        amount: "",
        comment: "",
        approver_id: "",
        req_user: "",
        modeOfPayment: "",
      },

      vendorList: [],
      projectList: [
        {
          project_name: "abc",
          project_id: 1,
        },
      ],
      subcategoryList: [],
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
        addRemoveLinks: true,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      selectedFilesUUID: [],
      uploadedDocumentArray: [],
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len <=
            Object.keys(newVal).filter(
              (x, index) =>
                newVal[x] &&
                newVal[x] !== undefined &&
                String(newVal[x]).trim().length > 0
            ).length &&
          this.form.currency &&
          this.form.comment
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    getMasterList: function () {
      MethodService.getAllMaster()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.paymentMode = data.data.payment_mode;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },
    onSelectBehalf(selected) {
      this.getApproverList(this.form.projectName.project_id, selected.user_id);
    },
    onProjectSelect: function () {},

    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getVendorList: function () {
      ContractService.getContractVendorNew()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.vendorList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getShootLocationList: function () {
      ShootLocationService.getActiveShootLocation()
        .then((response) => {
          const { data } = response;

          if (data.Status == false) {
          } else {
            this.shootLocationList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCurrencyList: function () {
      CurrencyService.getCurrency()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.currencyList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getProjectsList: function () {
      this.projects = [];
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes("2P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getAllUplodeFiles: function () {
      this.uploadFilePopup = true;
    },
    onChangeCheckUploadFile: function (doc) {
      window.open(doc);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },
    categoryList: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
      this.getMapList(this.project_id);
    },

    getMapList: function (id) {
      let payload = {
        project_id: id,
      };
      UserService.getMapList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.BehalfOfList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getApproverList: function (id, user_id) {
      let payload = {
        id: id,
        user_id: user_id,
      };
      UserService.getApproverList(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.ApproverList = data.data;
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    submitForm: function () {
      this.$vs.loading();
      setTimeout((x) => {
        let approver_id = "0";
        if (this.form.approver_id.length > 0) {
          approver_id = "";
          this.form.approver_id.map((item) => {
            approver_id += item.user_id + ",";
          });

          var lastChar = approver_id.slice(-1);
          if (lastChar == ",") {
            approver_id = approver_id.slice(0, -1);
          }
        }
        let payload = {
          project_id: this.form.projectName.project_id,
          po_name: this.form.vendorName,
          item_name: "-",
          company_name: "-",
          category_id: this.form.subcategoryValue
            ? this.form.subcategoryValue.category_id
            : 0,
          customFieldValues: [],
          company_address: "-",
          price: this.form.amount,
          currency: this.form.currency ? this.form.currency.currency_code : "",
          purchase_image: this.uploadedDocument,
          payment_mode: this.form.modeOfPayment
            ? this.form.modeOfPayment.payment_mode_id
            : "",
          sgst: "0",
          cgst: "0",
          total: this.form.amount,
          purchase_description: this.form.comment,
          location_id: this.form.shootLocation
            ? this.form.shootLocation.location_id
            : "",
          purchase_status: "0",
          approved_percentage: "0",
          purchase_active: "1",
          payment_mode_description: "-",
          purchase_type: "cash_request",
          approver_id: approver_id,
          vendorName: this.form.vendorName,
          vendor_id: 0,
          onBehalfId: this.form.req_user ? this.form.req_user.user_id : 0,
        };

        let vendorCheck = this.vendorList.filter((x) => {
          return x.vendor_name == this.form.vendorName;
        });
        if (vendorCheck.length > 0) {
          payload["vendor_id"] = vendorCheck[0].vendor_id;
        }

        CashManagementService.addCashRequest(payload)
          .then((response) => {
            const { data } = response;
            this.$vs.loading.close();
            if (data.Status == true) {
              this.$vs.notify({
                title: "Created!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
            }
            this.clearForm();
          })
          .catch((error) => {
            this.$vs.notify({
              title: "Error!",
              text: error.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      }, 1000);
    },

    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    vdropzoneFilesAdded: function (file) {},
    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocumentArray.push(response.data.image_path);
      this.fileUpload.deleteButtonStatus = true;
      this.uploadedDocument = this.uploadedDocumentArray.toString();
    },
    vdropzoneComplete: function (response) {
      for (let i = 0; i < response.length; i++) {
        this.selectedFilesUUID.push(response[i].upload.uuid);
      }
    },
    clearFile: function () {
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.uploadedDocument = "-";
      this.uploadedDocumentArray = [];
      this.selectedFilesUUID = [];
    },
    vdropzoneError: function (file, message, xhr) {},
    vremoved: function (file) {
      let uploaded = this.uploadedDocument.split(",");
      var a = this.selectedFilesUUID.indexOf(file.upload.uuid);
      if (a > -1) {
        uploaded.splice(a, 1);
        this.selectedFilesUUID.splice(a, 1);
        this.uploadedDocumentArray.splice(a, 1);
      }
      this.uploadedDocument = uploaded.toString();
      if (uploaded.length <= 0) {
        this.fileUpload.deleteButtonStatus = false;
        this.uploadedDocument = "-";
        this.uploadedDocumentArray = [];
        this.selectedFilesUUID = [];
      }
    },
    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      this.form = {
        vendorName: "",
        categoryValue: "",
        currency: "",
        amount: "",
        comment: "",
        approver_id: "",
        req_user: "",
        modeOfPayment: "",
      };
      this.reason = "";
      this.submitStatus = true;
      this.uploadedDocument = "-";
      this.clearFile();
    },
  },
};
</script>
